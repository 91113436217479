import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';
import CartDropdown from '../CartDropdown';
import UserDropdown from '../UserDropdown';
import websiteImage from '../../assets/images/codeIgnitelogo@3x.png';
import { UserContext } from '../../contexts/userContext';
import axios from 'axios';
import { apiUrl, fileUrl } from '../../apiUrl';

axios.defaults.withCredentials = true;

const Header = () => {
  const [ userInfo, setUser ] = useState("");
  const [otherUserDetails, setOtherUserDetails] = useState({});

  useEffect(() => {
    if(JSON.parse(localStorage.getItem('user'))){
      setOtherUserDetails(JSON.parse(localStorage.getItem('user')))
      setUser(JSON.parse(localStorage.getItem('user')))
    }
  }, [setUser]);

  // useEffect(() => {
  //   axios.get(`${apiUrl}/profile`, { withCredentials: true })
  //     .then(response => {
  //       // setUser(response);
  //       setOtherUserDetails(response.data || JSON.parse(localStorage.getItem('user')));
  //       const splitText = response.data.image.split("\\");
  //       const rem_split = splitText[splitText.length - 1];
  //       setOtherUserDetails({ ...otherUserDetails, image: rem_split });
  //     })
  //     .catch(err => {
  //       // setUser("");
  //       // setOtherUserDetails("");
  //     });
  // }, [otherUserDetails, setUser]);

  const { theme, toggleTheme } = useTheme();
  const [cartOpen, setCartOpen] = useState(false);
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [navOpen, setNavOpen] = useState(false);

  const logout = (e) => {
    e.preventDefault();
    axios.post(`${apiUrl}/logout`, { withCredentials: true })
      .then((res) => {
        setUser("");
        localStorage.removeItem("user");
        setOtherUserDetails("");
        window.location.href = "/";
        console.log(res);
      });
  }

  const user = {
    image: `${fileUrl}${otherUserDetails.image}`,
    name: otherUserDetails.name,
    email: otherUserDetails.email,
    theme: theme
  };

  return (
    <HeaderContainer theme={theme}>
      <HeaderContent>
        <HomeNav href="/">
          <Logo src={websiteImage} alt="Website Logo" />
        </HomeNav>
        <ThemeIconSmall onClick={toggleTheme}>{theme === 'light' ? '🌙' : '☀️'}</ThemeIconSmall>
        <HamburgerMenu onClick={() => setNavOpen(!navOpen)}>
          ☰
        </HamburgerMenu>
      </HeaderContent>
      <Nav navOpen={navOpen}>
        <SearchBox placeholder="Search..." />
        <NavItem href="/">Home</NavItem>
        <NavItem href="/contact">Contact</NavItem>
        <>
          {(!userInfo) ?
            <>
              <NavItemButton href="/signup" theme={theme}>SignUp</NavItemButton>
              <NavItemButton href="/login" theme={theme}>Login</NavItemButton>
            </>
            : <NavItemButton href="/login" onClick={logout} theme={theme}>Logout</NavItemButton>
          }
        </>
        <>
            <ThemeCartUserIcon>
            <ThemeIcon onClick={toggleTheme}>{theme === 'light' ? '🌙' : '☀️'}</ThemeIcon>
          {userInfo &&
          <>
              <UserImage
                src={user.image}
                alt="User"
                onClick={() => setUserDropdownOpen(!userDropdownOpen)}
              />
              {userDropdownOpen && <UserDropdown user={user} />}
              <CartIcon
                onMouseEnter={() => setCartOpen(true)}
                onMouseLeave={() => setCartOpen(false)}
              >
                🛒
                {cartOpen && <CartDropdown theme={theme}/>}
              </CartIcon>
              </>
              }
            </ThemeCartUserIcon>
        </>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;

// Styled Components

const HeaderContainer = styled.header`
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  background-color: ${(props) => (props.theme === 'light' ? '#fff' : '#333')};
  color: ${(props) => (props.theme === 'light' ? '#000' : '#fff')};
  z-index: 1000;
  box-shadow: ${(props) => props.theme === 'light' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.5)'};
  width: 100%;
  padding: 15px 5px;

  @media (max-width: 768px) {
    padding: 10px;
      flex-direction: column;
  }
`;

const HeaderContent = styled.div`
display: flex;
align-items: center;
justify-content: flex-start; /* changed from space-between to flex-start */
@media (max-width: 768px) {
justify-content: space-between;
width : 100%;
  }
`;

const HomeNav = styled.a`
  &:hover {
    opacity: 0.5;
  }
`;

const Logo = styled.img`
  max-height: 70px;

  @media (max-width: 768px) {
    height: 50px;
  }
`;

const HamburgerMenu = styled.div`
  display: none;
  font-size: 2rem;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-right: 0;
    width: 100%;
    display: ${(props) => (props.navOpen ? 'flex' : 'none')};
  }
`;

const NavItem = styled.a`
  margin: 0 1rem;
  text-decoration: none;
  color: inherit;
  position: relative;

  &:hover {
    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #002379;
      border-radius: 50px;
      animation: border-animation 1s infinite;
    }
  }

  @media (max-width: 768px) {
    margin: 0.5rem 0;
  }
`;

const NavItemButton = styled.a`
  margin: 0 1rem;
  text-decoration: none;
  color: inherit;
  position: relative;
  padding: 5px 15px;
  background-color: ${props => props.theme === 'light' ? '#007bff' : '#fff'};
  color: ${props => props.theme === 'light' ? '#ffffff' : '#000000'};
  box-shadow: 2px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #002379;
      border-radius: 50px;
      animation: border-animation 1s infinite;
    }
  }

  @media (max-width: 768px) {
    margin: 0.5rem 0;
  }
`;

const ThemeIcon = styled.span`
  cursor: pointer;
  margin-right: 1rem;

@media (max-width: 768px) { 
  display: none;
}
`;

const ThemeIconSmall = styled.span`
  cursor: pointer;
  margin-right: 1rem;
  font-size : 1.2rem;
@media (min-width: 768px) { 
  display: none;
}
`;

const UserImage = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 30px;
    width: 30px;
  }
`;

const CartIcon = styled.div`
  position: relative;
  cursor: pointer;
  margin-left: 15px;

  @media (max-width: 768px) {
    margin-top: 5px;
  }
`;

const SearchBox = styled.input`
  width: 30%;
  padding: 0.5rem;
  border-radius: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border: none;
  outline: none;
  box-shadow: '0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1)';

  @media (max-width: 768px) {
    width: 80%;
  }
`;

const ThemeCartUserIcon = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    padding-right: 10px;
  }
`;
