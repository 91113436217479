import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useTheme } from '../contexts/ThemeContext';
import { UserContext } from '../contexts/userContext';
import useUserAuthHook from '../hooks/useUserAuthHook'
import { showErrorNotification } from '../components/Layouts/Notification';
import CenteredLoader from "../components/Layouts/CenteredLoader";
import { apiUrl, fileUrl } from '../apiUrl';

const ShoppingCartPage = () => {
  const { theme } = useTheme();
  const { userInfo, setUser } = useContext(UserContext);
  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(true);
  const data = useUserAuthHook();

  const sort_image_url = (list_of_urls) => {
    return list_of_urls.map((value) => {
      const sliced_image = value.course_image.split("\\");
      const extracted_image = sliced_image[sliced_image.length - 1];
      return { ...value, course_image: extracted_image };
    });
  };

  useEffect(() => {
    if(data) {
      setUser(data);
    }
  }, [data, setUser]);

  useEffect(() => {
    if (!userInfo) {
      window.location.href = '/login';
      return;
    }
    const fetchCourses = async () => {
      try {
        await axios.get(`${apiUrl}/get-shopping-cart`, { 
          params: {
            userId: userInfo.id
          }
        }).then((response) => {
          const updated_array = sort_image_url(response.data);
          const fetchedCourses = updated_array;
          setCourses(fetchedCourses);
          setSelectedCourses(updated_array.map(course => course._id));
          const total = fetchedCourses.reduce((acc, course) => acc + parseFloat(course.price), 0);
          setTotalPrice(total);
          setLoading(false);
        }).catch((error) => {
          console.log(error.message);
        });
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, [userInfo]);

  const handleRemove = async (courseId) => {
    try {
      await axios.delete(`${apiUrl}/delete-shopping-cart`, { data: { courseId } });
      const updatedCourses = courses.filter(course => course.id !== courseId);
      setCourses(updatedCourses);
      setSelectedCourses(selectedCourses.filter(id => id !== courseId));
      const total = updatedCourses.reduce((acc, course) => acc + parseFloat(course.price), 0);
      setTotalPrice(total);
    } catch (error) {
      console.error('Error removing course:', error);
    }
  };

  const handleSaveLater = async (courseId) => {
    try {
      await axios.post(`${apiUrl}/add-course-favorite`, { courseId });
      handleRemove(courseId);
    } catch (error) {
      console.error('Error saving course for later:', error);
    }
  };

  const handleCheckboxChange = (courseId) => {
    const isSelected = selectedCourses.includes(courseId);
    const updatedSelectedCourses = isSelected
      ? selectedCourses.filter(id => id !== courseId)
      : [...selectedCourses, courseId];
    setSelectedCourses(updatedSelectedCourses);

    const selectedCourse = courses.find(course => course._id === courseId);
    const priceAdjustment = isSelected
      ? -parseFloat(selectedCourse.price)
      : parseFloat(selectedCourse.price);
    setTotalPrice(prevTotal => prevTotal + priceAdjustment);
  };

  const handleCheckout = () => {
    const selectedCourseIds = selectedCourses.join(',');
    window.location.href = `/checkout/${selectedCourseIds}`;
  };

  return (
    loading ? (
      <CenteredLoader />
    ) : (
      <ShoppingCartContainer theme={theme}>
        <Column>
          <BoldText theme={theme}>Shopping Cart</BoldText>
          {courses.map(course => (
            <CourseRow key={course._id}>
              <Checkbox
                type="checkbox"
                checked={selectedCourses.includes(course._id)}
                onChange={() => handleCheckboxChange(course._id)}
                name="selectedItems"
              />
            <NavLink href={`/single-course/${course._id}`} theme={theme}>
              <CourseImage src={`${apiUrl}//course_images/${course.course_image}`} alt="Course Thumbnail" />
            </NavLink>
              <CourseDetails>
            <NavLink href={`/single-course/${course._id}`} theme={theme}>
                <CourseTitle theme={theme}>{course.course_title}</CourseTitle>
            </NavLink>
                <CoursePrices>
                  <DiscountPrice>${(course.price * 0.5).toFixed(2)}</DiscountPrice>
                  <RealPrice>${course.price}</RealPrice>
                </CoursePrices>
                <CourseActions>
                  <ActionButton onClick={() => handleRemove(course._id)} theme={theme}>Remove</ActionButton>
                  <ActionButton onClick={() => handleSaveLater(course._id)} theme={theme}>Save for Later</ActionButton>
                </CourseActions>
              </CourseDetails>
            </CourseRow>
          ))}
        </Column>
        <ColumnLeft>
          <BoldText theme={theme}>Total</BoldText>
          <TotalPrice>${(totalPrice * 0.5).toFixed(2)}</TotalPrice>
          <CheckoutButton onClick={handleCheckout}>Checkout</CheckoutButton>
        </ColumnLeft>
      </ShoppingCartContainer>
    )
  );
};

const ShoppingCartContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  color: ${props => props.theme === 'light' ? '#333' : '#fff'};
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Column = styled.div`
  flex: 2;
  padding: 1rem;

  @media (max-width: 780px){
  padding: 0.3rem;

  }
`;

const ColumnLeft = styled.div`
  flex: 1;
  padding: 1rem;
  @media (max-width: 767px) {
    width: 100%;
    margin-top: 1rem;
    padding: 0.3rem;

  }
`;

const BoldText = styled.h2`
  font-weight: bold;
  color: ${props => props.theme === 'light' ? '#000' : '#fff'};
  margin-bottom: 1rem;
`;

const CourseRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  @media (max-width: 767px) {
    flex-direction: row;
    align-items: center;
  }
`;

const Checkbox = styled.input`
  margin-right: 1rem;
  font-size:1rem;
  width: 20px;
  height: 20px;
`;

const CourseImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 1rem;
  @media (min-width: 768px) {
    width: 200px;
    height: 200px;
  }
`;

const CourseDetails = styled.div`
  flex: 1;
`;

const CourseTitle = styled.p`
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: ${props => props.theme === 'light' ? '#000' : '#fff'};
  @media (max-width: 767px) {
    font-size: 0.7rem;
    margin-bottom: 0.1rem;

  }
`;

const CoursePrices = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  @media (max-width: 767px) {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 0.1rem;

  }
`;

const DiscountPrice = styled.span`
  color: red;
  font-weight: bold;
  color: ${(props) => (props.theme === 'light' ? '#4535c1' : '#ffd35a')};
  @media (max-width: 767px) {
    font-size: 0.9rem;
  }
`;

const RealPrice = styled.span`
  text-decoration: line-through;
  @media (max-width: 767px) {
    font-size: 0.7rem;
  }
`;

const CourseActions = styled.div`
  display: flex;
  gap: 0.5rem;
  @media (max-width: 767px) {
    flex-direction: row;
    align-items: center;
  }
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  color: blue;
  color : ${props => props.theme === 'light' ? '#8d493a' : '#ff8225'};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 767px) {
    font-size: 0.7rem;
  }
`;

const TotalPrice = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1rem 0;
  @media (max-width: 767px) {
    font-size: 1.25rem;
  }
`;

const CheckoutButton = styled.button`
  padding: 0.75rem;
  background-color: ${props => props.theme === 'light' ? '#007bff' : '#1a73e8'};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  &:hover {
    background-color: ${props => props.theme === 'light' ? '#0056b3' : '#135ab6'};
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 1rem;
  }
`;

const NavLink = styled.a`
text-decoration : none;
font-style: none;
color : ${props => props.theme === 'light' ? '#000' : '#fff'};
:hover {
color : ${props => props.theme === 'light' ? '#8d493a' : '#ff8225'};

}

`;

export default ShoppingCartPage;
