import React from 'react';
import styled from 'styled-components';

const UserDropdown = ({ user }) => {
  return (
    <DropdownContainer theme={user.theme}>
      <UserInfo>
        <UserImage src={user.image} alt="User" />
        <UserDetails>
          <UserName>{user.name}</UserName>
          <UserEmail>{user.email}</UserEmail>
        </UserDetails>
      </UserInfo>
      <Menu>
        <MenuItem href="/user-dashboard">Dashboard</MenuItem>
        <MenuItem href="/profile-settings">Payment Settings</MenuItem>
        {(user.isInstructor === true || user.isInstructor === 'true') &&
        <MenuItem href="/messages">Admin Dashbaord</MenuItem>
      }
      </Menu>
    </DropdownContainer>
  );
};

export default UserDropdown;

// Styled Components
const DropdownContainer = styled.div`
  position: absolute;
  top: 50px;
  right: 10px;
  width: 300px;
  background-color: ${(props) => (props.theme === 'light') ? '#f4f4f4' : '#333333'};
  box-shadow: ${(props) => props.theme === 'light' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.5)'};
  z-index: 1000;
  border-radius: 15px;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #f1f1f1;
`;

const UserImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const UserDetails = styled.div`
  margin-left: 10px;
  width : inherit;
`;

const UserName = styled.h4`
  margin: 0;
  font-size: 14px;
`;

const UserEmail = styled.p`
  margin: 0;
  color: #002379;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled.a`
  padding: 10px;
  text-decoration: none;
  color: #002379;
  &:hover {
    background-color: #f1f1f1;
  }
`;
