import React, { useState, useEffect, useContext } from "react";
import { UserContext } from '../contexts/userContext';
import useUserAuthHook from './useUserAuthHook';
import { showErrorNotification, showSuccessNotification } from "../components/Layouts/Notification";

const useAuthPageAccess = () => {
  const { userInfo, setUser } = useContext(UserContext);
  const [referrer, setReferrerOrigin] = useState('');
  const [currentOrigin, setCurrentOrigin] = useState('');
  const [localStorageReferrer, setLocalStorageReferrer] = useState('');
  const [loading, setLoading] = useState(true);

  const data = useUserAuthHook();

  useEffect(() => {
    setUser(data);
    setReferrerOrigin(document.referrer);
    setCurrentOrigin(window.location.origin);
    const referrerPage = window.localStorage.getItem('referrerPage');
    setLocalStorageReferrer(referrerPage);
    setLoading(false);
  }, [data, setUser]);

  useEffect(() => {
    if (!loading && userInfo) {
      setLoading(true);
      if (referrer !== currentOrigin) {
        window.location.href = '/';
      } else {
        window.location.href = localStorageReferrer || '/';
      }
    } else if (!loading && !userInfo) {
      showErrorNotification(userInfo);
    }
  }, [userInfo, referrer, currentOrigin, localStorageReferrer, loading]);
};

export default useAuthPageAccess;