import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../../contexts/userContext';
import { apiUrl, fileUrl } from '../../apiUrl';

const CourseCard = ({ course }) => {
  const { theme } = useTheme();
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const { userInfo, setUser } = useContext(UserContext);

  useEffect(() => {
    axios.get(`${apiUrl}/profile`, {
      withCredentials: true,
    }).then(response => {
      setUser(response.data);
    }).catch(err => {
      console.log(err);
    });
  }, []);

  const saveToShoppingCart = async (id) => {
    const data = {
      userId: userInfo.id,
      courseId: id
    }
    await axios.post(`${apiUrl}/add-shopping-cart`, data)
      .then(res => {
        navigate('/shopping-cart');
      }).catch(err => {
        console.log(err);
      });
  }

  return (
    <>
      <Card
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        theme={theme}
      >
        <NavLink href={course.link} >
          <ImageContainer>
            <CourseImage src={`${fileUrl}${course.course_image}`} alt={course.title} />
          </ImageContainer>
        </NavLink>
        <Content>
          <NavLink href={`/single-course/${course._id}`} theme={theme}>
            <CourseTitle>{course.course_title}</CourseTitle>
          </NavLink>
          <NavLink href={`/single-course/${course._id}`} theme={theme}>
            <CourseMode>
              Course Mode: <ModeBadge>{course.mode}</ModeBadge>
            </CourseMode>
          </NavLink>
          <Price>
            <DiscountPrice theme={theme}>#{Intl.NumberFormat('en-us').format(course.price)}</DiscountPrice>
            <OriginalPrice>#{Intl.NumberFormat('en-us').format(course.price * 1.80)}</OriginalPrice>
          </Price>
          <Buttons>
            <Button onClick={() => saveToShoppingCart(course._id)}>Add to Cart</Button>
            <Button primary onClick={() => navigate(`/checkout/${course._id}`)}>Enroll Now</Button>
          </Buttons>
        </Content>
      </Card>
      {hover && (
        <Popup theme={theme}>
          <PopupTitle>{course.title}</PopupTitle>
          <PopupDuration>Duration: {course.duration} weeks</PopupDuration>
          <PopupDescription>{course.description}</PopupDescription>
          <PopupList>
            {course.higlight}
          </PopupList>
        </Popup>
      )}
    </>
  );
};

export default CourseCard;

// Styled Components

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: ${(props) => props.theme === 'light' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.5)'};
  background-color: ${(props) => (props.theme === 'light' ? '#fff' : '#333')};
  color: ${(props) => (props.theme === 'light' ? '#000' : '#fff')};
  position: relative;
  transition: box-shadow 0.3s ease;
  margin: 10px;

  @media (min-width: 992px) {
    width: calc(33.333% - 20px);
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: calc(50% - 20px);
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 200px;
  overflow: hidden;
`;

const CourseImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Content = styled.div`
  padding: 15px;
`;

const CourseTitle = styled.h3`
  margin: 0;
  font-size: 1.2rem;
`;

const CourseMode = styled.div`
  margin: 10px 0;
`;

const ModeBadge = styled.span`
  background-color: #4caf50;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.9rem;
  margin-left: 5px;
`;

const Price = styled.div`
  margin: 10px 0;
  font-size: 1.3rem;
`;

const DiscountPrice = styled.span`
  color: ${(props) => props.theme === 'light' ? 'red' : '#fff'};
  font-weight: bolder;
  margin-right: 10px;
`;

const OriginalPrice = styled.span`
  text-decoration: line-through;
  color: gray;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Button = styled.button`
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  background-color: ${(props) => (props.primary ? '#007bff' : '#28a745')};
  color: white;
  box-shadow: ${(props) =>
    props.theme === 'light' ? '0 4px 6px rgba(0, 0, 0, 0.1)' : '0 4px 6px rgba(0, 0, 0, 0.5)'};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.primary ? '#0056b3' : '#218838')};
  }
`;

const Popup = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  z-index: 10000;
  padding: 20px;
  border-radius: 10px;
  background-color: ${(props) => (props.theme === 'light' ? '#fff' : '#333')};
  box-shadow: ${(props) =>
    props.theme === 'light' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.5)'};
  color: ${(props) => (props.theme === 'light' ? '#000' : '#fff')};
  transform: translate(-50%, -50%);
  display: ${(props) => (props.show ? 'block' : 'none')};
`;

const PopupTitle = styled.h4`
  margin: 0 0 10px 0;
`;

const PopupDuration = styled.p`
  margin: 0 0 10px 0;
`;

const PopupDescription = styled.p`
  margin: 0 0 10px 0;
`;

const PopupList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const NavLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme === 'light' ? '#000' : '#fff'};
`;
