import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useTheme } from '../contexts/ThemeContext';
import { FaUser, FaEdit, FaTrashAlt } from 'react-icons/fa';
import { MdMessage, MdNotifications } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/userContext';
import { apiUrl } from '../apiUrl';

axios.defaults.withCredentials = true;

const InstructorDashboard = () => {
  const { theme } = useTheme();
  const {userInfo, setUser} = useContext(UserContext);
  const [selectedMenu, setSelectedMenu] = useState('courses');
  const [courses, setCourses] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [page, setPage] = useState(1);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const history = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const url = `${apiUrl}/get-${selectedMenu}?page=${page}`;
      // alert(Object.entries(userInfo.data));
      try {
        await axios.get(url, {
          params : {user_id : userInfo.data.id}
        }).then(res => {
          // alert("info ready");
        if (selectedMenu === 'courses') {
          setCourses(res.data);
        } else if (selectedMenu === 'quizzes') {
          setQuizzes(res.data);
        } else if (selectedMenu === 'assignments') {
          setAssignments(res.data);
        }
          // alert("Fetching Content", res);
        }).catch(err => {
          // alert("Error fetching : " + err);
        });
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  },[selectedMenu, userInfo, page]);

  const handleDelete = async (id) => {
    const url = `${apiUrl}/delete-course`;
    try {
      await axios.delete(url, {
        params : {
          id : userInfo.data.id,
          course_id : id
        }
      }).then(res => {
        // alert("Sucess Deleting course : ", res);
      }).catch(err => {
        // alert("Request error : ", err);
      });

      if (selectedMenu === 'courses') {
        setCourses(prev => prev.filter(item => item._id !== id));
      } else if (selectedMenu === 'quizzes') {
        setQuizzes(prev => prev.filter(item => item._id !== id));
      } else if (selectedMenu === 'assignments') {
        setAssignments(prev => prev.filter(item => item._id !== id));
      }
      setShowDeletePopup(false);
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleEdit = (id) => {
    const url = `/${selectedMenu.toLowerCase()}-edit?id=${id}`;
    history(url);
  };

  const confirmDelete = () => {
    handleDelete(deleteItemId);
  };

  const cancelDelete = () => {
    setShowDeletePopup(false);
    setDeleteItemId(null);
  };

  return (
    <DashboardContainer theme={theme}>
      <Sidebar theme={theme}>
        <Profile>
          <FaUser size={40} />
          <UserName>John Doe</UserName>
        </Profile>
        <Menu>
          <MenuItem theme={theme} onClick={() => { setSelectedMenu('courses'); setPage(1); setCourses([]); }}>Courses</MenuItem>
          <MenuItem theme={theme} onClick={() => { setSelectedMenu('quizzes'); setPage(1); setQuizzes([]); }}>Quizzes</MenuItem>
          <MenuItem theme={theme} onClick={() => { setSelectedMenu('assignments'); setPage(1); setAssignments([]); }}>Assignments</MenuItem>
        </Menu>
      </Sidebar>
      <MainContent>
        <Overview>
          <Card>
            <CardTitle>Total Courses</CardTitle>
            <CardContent>5</CardContent>
          </Card>
          <Card>
            <CardTitle>Total Students</CardTitle>
            <CardContent>120</CardContent>
          </Card>
          <Card>
            <CardTitle>New Messages</CardTitle>
            <CardContent><MdMessage /> 3</CardContent>
          </Card>
          <Card>
            <CardTitle>Notifications</CardTitle>
            <CardContent><MdNotifications /> 2</CardContent>
          </Card>
        </Overview>
        <ContentSection>
          <SectionTitle>{selectedMenu} Management</SectionTitle>
          <Table>
            <thead>
              <tr>
                <th>Title</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {(selectedMenu === 'courses' ? courses : selectedMenu === 'quizzes' ? quizzes : assignments).map(item => (
                <tr key={item._id}>
                  <td>{item.course_title}</td>
                  <td>
                    <ActionButton onClick={() => handleEdit(item._id)}><FaEdit /> Edit</ActionButton>
                    <ActionButton onClick={() => { setDeleteItemId(item._id); setShowDeletePopup(true); }}><FaTrashAlt /> Delete</ActionButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <LoadMoreButton onClick={() => setPage(prev => prev + 1)}>Load More</LoadMoreButton>
          {selectedMenu === 'courses' && <AddButton href="/course-creation">Add New Course</AddButton>}
          {selectedMenu === 'quizzes' && <AddButton href="/quiz-creation">Add New Quiz</AddButton>}
          {selectedMenu === 'assignments' && <AddButton href="/assignment-creation">Add New Assignment</AddButton>}
        </ContentSection>
        {showDeletePopup && (
          <DeletePopup>
            <PopupContent>
              <p>Do you want to delete this item?</p>
              <PopupActions>
                <PopupButton onClick={confirmDelete}>Delete</PopupButton>
                <PopupButton onClick={cancelDelete}>Cancel</PopupButton>
              </PopupActions>
            </PopupContent>
          </DeletePopup>
        )}
      </MainContent>
    </DashboardContainer>
  );
};

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
`;

const Sidebar = styled.div`
  width: 250px;
  background-color: ${props => props.theme === 'light' ? '#fff' : '#333'};
  color: ${props => props.theme === 'light' ? '#000' : '#fff'};
  box-shadow: ${props => props.theme === 'light' ? '2px 0 5px rgba(0, 0, 0, 0.1)' : '2px 0 5px rgba(0, 0, 0, 0.5)'};
  padding: 20px;
  overflow-y: auto;
`;

const Profile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const UserName = styled.div`
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
`;

const Menu = styled.div`
  margin-top: 20px;
`;

const MenuItem = styled.div`
  padding: 10px 0;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme === 'light' ? '#f0f0f0' : '#444'};
    color: ${props => props.theme === 'light' ? '#000' : '#fff'};
  }
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
`;

const Overview = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Card = styled.div`
  flex: 1;
  background-color: ${props => props.theme === 'light' ? '#fff' : '#444'};
  color: ${props => props.theme === 'light' ? '#000' : '#fff'};
  padding: 20px;
  margin: 0 10px;
  box-shadow: ${props => props.theme === 'light' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.5)'};
  border-radius: 10px;
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const CardContent = styled.div`
  font-size: 24px;
`;

const ContentSection = styled.div`
  margin-bottom: 20px;
`;

const SectionTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;

  th, td {
    border: 1px solid ${props => props.theme === 'light' ? '#ddd' : '#555'};
    padding: 10px;
    text-align: left;
  }

  th {
    background-color: ${props => props.theme === 'light' ? '#f9f9f9' : '#666'};
  }
`;

const ActionButton = styled.button`
  margin-left: 10px;
  padding: 5px 10px;
  background-color: ${props => props.theme === 'light' ? '#f0f0f0' : '#555'};
  color: ${props => props.theme === 'light' ? '#000' : '#fff'};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme === 'light' ? '#ddd' : '#666'};
  }
`;

const LoadMoreButton = styled.button`
  padding: 10px 20px;
  background-color: ${props => props.theme === 'light' ? '#007bff' : '#0056b3'};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme === 'light' ? '#0056b3' : '#004085'};
  }
`;

const AddButton = styled.a`
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: ${props => props.theme === 'light' ? '#28a745' : '#38c745'};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background-color: ${props => props.theme === 'light' ? '#218838' : '#2ebf39'};
  }
`;

const DeletePopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupContent = styled.div`
  background: ${props => props.theme === 'light' ? '#fff' : '#333'};
  padding: 20px;
  border-radius: 5px;
  text-align: center;
`;

const PopupActions = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

const PopupButton = styled.button`
  margin: 0 10px;
  padding: 10px 20px;
  background-color: ${props => props.theme === 'light' ? '#dc3545' : '#ff4f5e'};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme === 'light' ? '#c82333' : '#ff2e4a'};
  }
`;

export default InstructorDashboard;
