import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import styled from 'styled-components';

const RichTextEditor = ({ name, value, onChange, label, theme }) => {
  const handleChange = (content) => {
    onChange({ target: { name, value: content } });
  };

  return (
    <FormElement theme={theme}>
      <Label theme={theme}>{label}</Label>
      <StyledReactQuill theme={theme} value={value} onChange={handleChange} />
    </FormElement>
  );
};

const FormElement = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-weight: bold;
  color: ${props => (props.theme === 'light' ? '#000' : '#fff')};
  margin-bottom: 5px;
`;

const StyledReactQuill = styled(ReactQuill)`
  .ql-container {
    background-color: ${props => (props.theme === 'light' ? '#fff' : '#2c2c2c')};
    color: ${props => (props.theme === 'light' ? '#000' : '#fff')};
    border: 1px solid ${props => (props.theme === 'light' ? '#ccc' : '#444')};
    border-radius: 5px;
    box-shadow: ${props => (props.theme === 'light' ? '0 2px 4px rgba(0, 0, 0, 0.1)' : '0 2px 4px rgba(0, 0, 0, 0.4)')};
  }

  .ql-toolbar {
    background-color: ${props => (props.theme === 'light' ? '#f9f9f9' : '#3c3c3c')};
    border: 1px solid ${props => (props.theme === 'light' ? '#ccc' : '#444')};
    border-radius: 5px;
    color: ${props => (props.theme === 'light' ? '#000' : '#fff')};

  }

  .ql-editor {
    min-height: 200px;
  }
`;

export default RichTextEditor;
