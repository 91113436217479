import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { apiUrl } from '../apiUrl';

import axios from 'axios';

// Contact Form Component
const ContactPage = () => {
  const { theme } = useTheme();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    enquiry: '',
    subject: '',
    phoneNumber: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/contact`, formData);
      const { proposalUrl } = response.data;
      window.open(proposalUrl, '_blank');
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <PageContainer theme={theme}>
      <FormBox theme={theme}>
        <h3>Contact Us</h3>
        <form onSubmit={handleSubmit}>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />

          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />

          <label htmlFor="subject">Subject:</label>
          <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleChange} required />

          <label htmlFor="phoneNumber">Phone Number:</label>
          <input type="tel" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />

          <label htmlFor="enquiry">Enquiry:</label>
          <textarea id="enquiry" name="enquiry" value={formData.enquiry} onChange={handleChange} required />

          <button type="submit">Submit</button>
        </form>
      </FormBox>

      <ContactInfoBox theme={theme}>
        <h3>Contact Information</h3>
        <SocialBox>
          <a href="https://www.facebook.com/codeignitehandle" target="_blank" rel="noopener noreferrer">Facebook</a>
          <a href="https://www.youtube.com/@codeignitehandle" target="_blank" rel="noopener noreferrer">YouTube</a>
          <a href="https://www.instagram.com/codeignitehandle" target="_blank" rel="noopener noreferrer">Instagram</a>
          <a href="https://www.tiktok.com/@codeignitehandle" target="_blank" rel="noopener noreferrer">TikTok</a>
        </SocialBox>
        <ContactDetails>
          <p>Email: <a href="mailto:admin@codeignite.com">admin@codeignite.com</a></p>
          <p>Phone: <a href="tel:+2348143164036">+234 8143 1640 36</a></p>
        </ContactDetails>
      </ContactInfoBox>
    </PageContainer>
  );
};

// Styled Components
const PageContainer = styled.div`
  background-color: ${({ theme }) => theme === "light" ? "#ffffff" : "#333333"};
  color: ${({ theme }) => theme === "light" ? "#000000" : "#ffffff"};
  padding: 20px;
`;

const FormBox = styled.div`
  background-color: ${({ theme }) => theme === "light" ? "#f9f9f9" : "#444444"};
  border-radius: 10px;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  label {
    font-weight: bold;
  }

  input, textarea {
    padding: 10px;
    border: 1px solid ${({ theme }) => theme === "light" ? "#cccccc" : "#555555"};
    border-radius: 5px;
  }

  button {
    padding: 10px;
    background-color: ${({ theme }) => theme === "light" ? "#007BFF" : "#0056b3"};
    color: #ffffff;
    cursor: pointer;
  }
`;

const ContactInfoBox = styled.div`
  margin-top: 40px;
  text-align: center;

  h3 {
    margin-bottom: 20px;
  }
`;

const SocialBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;

  a {
    color: ${({ theme }) => theme === "light" ? "#007BFF" : "#80d2f2"};
    text-decoration: none;
    font-weight: bold;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const ContactDetails = styled.div`
  p {
    margin: 10px 0;
  }

  a {
    color: ${({ theme }) => theme === "light" ? "#007BFF" : "#80d2f2"};
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export default ContactPage;


