import { useEffect } from 'react';

const useSetReferrerPage = () => {
  useEffect(() => {
    const referrerPage = document.referrer;
    const currentOrigin = window.location.origin;

    // Check if the referrer page is not /login or /signup
    if (!referrerPage.endsWith('/login') && !referrerPage.endsWith('/signup')) {
      // Check if the referrer page is in the same website
      if (referrerPage.includes(currentOrigin)) {
        // Set the referrer page in local storage
        localStorage.setItem('referrerPage', referrerPage);
      }
    }
  }, []);
};

export default useSetReferrerPage;