import React, { createContext, useState, useContext, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userInfo, setUser] = useState({});

  return (
    <UserContext.Provider value={{ userInfo, setUser}}>
      {children}
    </UserContext.Provider>
  );
};
