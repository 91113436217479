import React from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import {useEffect, useState, useContext } from 'react'
import {UserContext} from '../contexts/userContext'
import { FaUserCircle, FaBars, FaCheck } from 'react-icons/fa'; // Importing icons from react-icons library
import useUserAuthHook from '../hooks/useUserAuthHook'
import { showErrorNotification } from '../components/Layouts/Notification';
import CenteredLoader from "../components/Layouts/CenteredLoader";
import axios from 'axios';
import { sortImageUrl } from '../utils/sortImageUrl';
import { Navigate } from 'react-router-dom';
import { apiUrl, fileUrl } from '../apiUrl';

const DashboardPage = () => {
  const { theme } = useTheme();
  const {userInfo, setUser} = useContext(UserContext);
  const [purchasedCourse, setPurchasedCourse] = useState([]);
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState();

  const data = useUserAuthHook();

  useEffect(() => {
    if(data) {
      setUser(data);
    }
  }, [data, setUser]);

  useEffect(() => {
  if(!userInfo) {
    window.location.href = "/login";
  }
  }, [userInfo]);

  useEffect(() => {
    try {
    if(Object.values(userInfo).length > 0) {
      const splitText = userInfo.image.split("\\");
      const rem_split = splitText[splitText.length - 1];
      setImage(rem_split)
    }
  } catch (e) {
    showErrorNotification("error fetching user image");
  }
  }, [userInfo]);

  useEffect(() => {
    axios.get(`${apiUrl}/get-payed-courses`, {
      params : {
        userId : userInfo.id
      }
    }).then((response) => {
      const data = sortImageUrl(response.data)
      setPurchasedCourse(data);
      if(userInfo) {
      setLoading(false);
      }
    }).catch((error) => {
      showErrorNotification(`error fetching course ${error}`);
    })
  },[userInfo]);

  return (
    loading ? (
      <>
      <CenteredLoader />
      </>
    ) : (
    <>
    <DashboardContainer>
      <Sidebar theme={theme}>
        <ProfileSection>
        <ProfileImageLeftLarge>
        <UserImage src={`${fileUrl}${image}`} />
        </ProfileImageLeftLarge>
        <ProfileDetailsLeftLarge>
          <ProfileTitle>User Profile</ProfileTitle>
          <ProfileItem>
            <ProfileLabel>Name:</ProfileLabel>
            <ProfileValue>{userInfo.name}</ProfileValue>
          </ProfileItem>
          <ProfileItem>
            <ProfileLabel>Email:</ProfileLabel>
            <ProfileValue>{userInfo.email}</ProfileValue>
          </ProfileItem>
          <ProfileItem>
            <ProfileLabel>Phone:</ProfileLabel>
            <ProfileValue>{userInfo.phoneNumber}</ProfileValue>
          </ProfileItem>
        </ProfileDetailsLeftLarge>
        </ProfileSection>
        <Menu>
          <MenuItem theme={theme}>Home</MenuItem>
          <MenuItem theme={theme}>Courses</MenuItem>
        </Menu>
        <MenuToggle>
          <FaBars size={24} color={theme === 'light' ? '#333' : '#ccc'} />
        </MenuToggle>
      </Sidebar>
      <MainContent>
        
        <ProfileImageLarge>
        <UserImage src={`${fileUrl}${image}`} />
        </ProfileImageLarge>

        <ProfileDetailsLarge>
        <ProfileItem>
            <ProfileLabel>Name:</ProfileLabel>
            <ProfileValue>{userInfo.name}</ProfileValue>
          </ProfileItem>
          <ProfileItem>
            <ProfileLabel>Email:</ProfileLabel>
            <ProfileValue>{userInfo.email}</ProfileValue>
          </ProfileItem>
          <ProfileItem>
            <ProfileLabel>Phone:</ProfileLabel>
            <ProfileValue>{userInfo.phoneNumber}</ProfileValue>
          </ProfileItem>
        </ProfileDetailsLarge>

          <SectionTitle theme={theme}>Enrolled Courses</SectionTitle>
        <EnrolledCoursesSection>
          {purchasedCourse &&
              purchasedCourse.map(course => (
          <>
          <CourseCard theme={theme}>

          <NavLink href={`/single-course/${course._id}`} theme={theme} >
          <CourseImage src={`${fileUrl}${course.course_image}`} alt={course.title} />
          </NavLink>

            <CourseInfo>
            <NavLink href={`/single-course/${course._id}`} theme={theme}>
          <CourseTitle>{course.course_title}</CourseTitle>
          </NavLink>
            <CourseItem>
            <ProfileLabel>Start Date:</ProfileLabel>
            <ProfileValue>August 1 2024</ProfileValue>
            </CourseItem>

            <CourseItem>
            <ProfileLabel>End Date:</ProfileLabel>
            <ProfileValue>August 30 2024</ProfileValue>
            </CourseItem>

            <CourseItem>
            <ProfileLabel>Course Schedule:</ProfileLabel>
            <ProfileValue>Mon-Fri</ProfileValue>
            </CourseItem>
            
            <CourseItem>
            <ProfileLabel>Progress: 60%</ProfileLabel>
            <ProfileValue><ProgressBar progress={60} /></ProfileValue>
            </CourseItem>
             
            </CourseInfo>
          </CourseCard>
          </>
              ))
              
            }
        </EnrolledCoursesSection>

        {/* <ProgressSection>
          <SectionTitle>Progress</SectionTitle>
          <ProgressBarContainer>
            <ProgressBar progress={60} />
            <ProgressDetails>60% Completed</ProgressDetails>
          </ProgressBarContainer>
        </ProgressSection> */}

        <AdditionalWidgetSection>
          <Widget theme={theme}>
            <WidgetTitle>Course Suggestions</WidgetTitle>
            <WidgetContent>Course suggestions based on your profile and interests.</WidgetContent>
          </Widget>
          <Widget theme={theme}>
            <WidgetTitle>Upcoming Events</WidgetTitle>
            <WidgetContent>Events related to your enrolled courses.</WidgetContent>
          </Widget>
        </AdditionalWidgetSection>

        <ProfileActions>
          <ActionButton>Edit Profile</ActionButton>
          <ActionButton>Logout</ActionButton>
        </ProfileActions>
      </MainContent>
    </DashboardContainer>
    </>
    )
  );
};

const DashboardContainer = styled.div`
  display: flex;
  margin: 0 auto;
  padding: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Sidebar = styled.div`
  min-width: 200px;
  background-color: ${props => props.theme === 'light' ? '#f0f0f0' : '#333'};
  color: ${props => props.theme === 'light' ? '#333' : '#ccc'};
  padding: 20px;
  box-shadow: ${props => props.theme === 'light' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.4)'};
  z-index: 1; /* Ensure the sidebar overlaps the main content slightly */

  @media (max-width: 768px) {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 200px;
    overflow-y: auto;
    transform: translateX(-200px);
    transition: transform 0.3s ease;

    &.open {
      transform: translateX(0);
    }
  }
`;

const ProfileSection = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const ProfileImage = styled.div`
  margin-right: 20px;
`;

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfileName = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
`;

const ProfileEmail = styled.p`
  font-size: 0.9rem;
  color: #666;
`;

const Menu = styled.div`
  margin-top: 20px;
`;

const MenuItem = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme === 'light' ? '#ddd' : '#444'};
  }
`;

const MenuToggle = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
  display: none;
  z-index: 2;

  @media (max-width: 768px) {
    display: block;
  }
`;

const MainContent = styled.div`
  flex: 1;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content on large screens */

  @media (max-width:780px){
  margin: 2px;
  }
`;

const ProfileImageLarge = styled.div`
  margin-bottom: 20px;
  @media (min-width: 768px) {
  box-shadow: ${(props) => props.theme === 'light' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.5)'};
  display : none;
  border-radius: 45%;

  }
`;

const ProfileImageLeftLarge = styled.div`
  margin-bottom: 20px;
  @media (max-width: 768px) {
  box-shadow: ${(props) => props.theme === 'light' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.5)'};
  display : none;
  border-radius: 45%;

  }
`;

const ProfileDetailsLarge = styled.div`
  margin-bottom: 40px;
  @media (min-width: 768px) {
  display : none;
  }
`;

const ProfileDetailsLeftLarge = styled.div`
  margin-bottom: 40px;
  text-align : center;
  @media (max-width: 768px) {
  display : none;
  }
`;

const ProfileTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
`;

const ProfileItem = styled.div`
  display: flex;
  justify-content : center;
  align-items: center;
  margin-bottom: 10px;
`;

const CourseItem = styled.div`
  display: flex;
  justify-content : space-between;
  margin-bottom: 10px;
`;

const ProfileLabel = styled.span`
  font-weight: bold;
  margin-right: auto;
`;

const ProfileValue = styled.span`
margin-left: auto;
`;

const EnrolledCoursesSection = styled.section`
  margin-bottom: 40px;
  display : flex;
  flex-wrap: wrap;

  @media (max-width: 768px) {
  display : column;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: ${props => props.theme === 'light' ? '#333333' : '#ccc'};

`;

const CourseCard = styled.div`
  flex-basis: 30%;
  box-sizing : border-box;
  margin: 0 5px 20px 5px;
  box-shadow: ${(props) => props.theme === 'light' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.5)'};
  background-color: ${(props) => (props.theme === 'light' ? '#fff' : '#333')};
  color: ${(props) => (props.theme === 'light' ? '#000' : '#fff')};
  border-radius: 8px;
  padding: 15px;

  @media (max-width: 768px) {
  flex-basis : 100%
  margin: 0 0 20px 0;
  }

  @media (min-width: 768px) and (max-width:1024px) {
  flex-basis: 47%;
  margin-left: 10px;
  
  }
`;

const CourseImage = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 8px;
  object-fit : cover;
  margin-bottom : 17px;
`;

const CourseInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
`;

const CourseTitle = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 17px;
`;

const CourseDetails = styled.p`
  font-size: 0.9rem;
  margin-bottom: 5px;
`;

const CourseProgress = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ProgressBarContainer = styled.div`
  flex: 1;
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 10px;
`;

const ProgressBar = styled.div`
  width: ${props => props.progress}%;
  height: 100%;
  background-color: #f0c14b;
`;

const ProgressDetails = styled.p`
  font-size: 0.9rem;
`;

const AdditionalWidgetSection = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 40px;
`;

const Widget = styled.div`
  background-color: ${props => props.theme === 'light' ? '#fff' : '#2c2c2c'};
  color: ${props => props.theme === 'light' ? '#333' : '#ccc'};
  box-shadow: ${props => props.theme === 'light' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.4)'};
  border-radius: 8px;
  padding: 20px;
`;

const WidgetTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
`;

const WidgetContent = styled.p`
  font-size: 0.9rem;
`;

const ProfileActions = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 40px;
`;

const ActionButton = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  background-color: #f0c14b;
  color: #111;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: ${props => props.theme === 'light' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.4)'};
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #ddb347;
  }
`;

const DashboardFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: ${props => props.theme === 'light' ? '#f0f0f0' : '#333'};
  color: ${props => props.theme === 'light' ? '#333' : '#ccc'};
  box-shadow: ${props => props.theme === 'light' ? '0 -4px 8px rgba(0, 0, 0, 0.1)' : '0 -4px 8px rgba(0, 0, 0, 0.4)'};
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1; /* Ensure it overlaps the main content slightly */
`;

const FooterLink = styled.a`
  color: ${props => props.theme === 'light' ? '#333' : '#ccc'};
  text-decoration: none;
  margin-right: 20px;
  transition: color 0.3s ease;

  &:hover {
    color: ${props => props.theme === 'light' ? '#111' : '#fff'};
  }
`;

const CourseButton = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  background-color: #f0c14b;
  color: #111;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  box-shadow: ${props => props.theme === 'light' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.4)'};
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #ddb347;
  }
`;

const UserImage = styled.img`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  cursor: pointer;
`;

const NavLink = styled.a`
text-decoration : none;
font-style: none;
color : ${props => props.theme === 'light' ? '#000' : '#fff'};

`;

export default DashboardPage;
