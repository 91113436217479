import DOMPurify from 'dompurify';

// Helper function to detect if a string contains HTML tags
const isHTML = (str) => {
  const pattern = /<\/?[a-z][\s\S]*>/i;
  return pattern.test(str);
};

// Function to sanitize inputs (strings, numbers, HTML)
const sanitizeInput = (input) => {
  if (typeof input === 'string') {
    // Detect if it's HTML or a plain string
    if (isHTML(input)) {
      // Sanitize HTML content and remove potentially dangerous tags
      return DOMPurify.sanitize(input);
    } else {
      // Sanitize plain string by removing harmful characters like script injections
      return input.replace(/[<>]/g, '');  // Simple sanitization example for plain strings
    }
  } else if (typeof input === 'number') {
    // For numbers, we simply return the value (nothing to sanitize)
    return input;
  }
  return input;
};

// Function to validate file types (video, PDF, images)
const validateFileType = (file, type) => {
  if (type === 'video') {
    return file.name.toLowerCase().endsWith('.mp4');
  } else if (type === 'pdf') {
    return file.name.toLowerCase().endsWith('.pdf');
  } else if (type === 'image') {
    return ['.jpeg', '.jpg', '.png'].some(ext => file.name.toLowerCase().endsWith(ext));
  }
  return false;
};

// Recursive function to sanitize form data
export const sanitizeFormData = (data) => {
  const sanitizedData = {};
  for (const key in data) {
    if (typeof data[key] === 'string' || typeof data[key] === 'number') {
      // Sanitize strings and numbers
      sanitizedData[key] = sanitizeInput(data[key]);
    } else if (Array.isArray(data[key])) {
      // Recursively sanitize arrays
      sanitizedData[key] = data[key].map(item => {
        if (typeof item === 'object') {
          return sanitizeFormData(item);
        }
        return sanitizeInput(item);
      });
    } else if (typeof data[key] === 'object' && data[key] !== null && data[key].type) {
      // Check for file types if the object is a file
      if (data[key].type.includes('video') && validateFileType(data[key], 'video')) {
        sanitizedData[key] = data[key]; // Valid MP4 video file
      } else if (data[key].type.includes('pdf') && validateFileType(data[key], 'pdf')) {
        sanitizedData[key] = data[key]; // Valid PDF file
      } else if (data[key].type.includes('image') && validateFileType(data[key], 'image')) {
        sanitizedData[key] = data[key]; // Valid image file
      } else {
        throw new Error(`Invalid file type for ${key}`);
      }
    } else {
      // Leave other data types untouched
      sanitizedData[key] = data[key];
    }
  }
  return sanitizedData;
};
