import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import Navigation from '../components/Courses/CourseNavigation';
import RichTextEditor from '../components/Common/RichTextEditor';
import { sanitizeFormData } from '../utils/sanitizationUtility';
import {useSelector, useDispatch} from 'react-redux';

import axios from 'axios';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { apiUrl } from '../apiUrl';
import CenterLoader2 from "../components/Layouts/CenteredLoader2";
import { showErrorNotification, showSuccessNotification } from "../components/Layouts/Notification";
axios.defaults.withCredentials = true;

const CourseCreationPage = ({ prevPage, nextPage }) => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const [userInfo, setUserInfo] = useState();

  useEffect(()=> {
    if (user && user !== undefined && user !== null) {
      alert(user);
      setUserInfo(user || JSON.parse(localStorage.getItem('user')));
    } else {
      setUserInfo(JSON.parse(localStorage.getItem('user')));
    }
  }, [user, userInfo, navigate]);

  // useEffect(() => {
  //   if ((!user && !userInfo)) {
  //     navigate("/");
  //   }
  // }, []);

  const [courseData, setCourseData] = useState({
    course_title: "",
    author: "",
    description: "",
    highlights: "",
    requirements: "",
    mode: "",
    price: '',
    course_image: "",
    course_video: "",
  });
  const [errors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCourseData({
      ...courseData,
      [name]: value
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCourseData({
      ...courseData,
      [name]: checked
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0]

    if(file) {
      setCourseData({...courseData, course_image:file});
    }
  };

  const handleVideoChange = (e) => {
    const file = e.target.files[0]

    if(file) {
      setCourseData({...courseData, course_video:file});
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setCourseData({...courseData, author:userInfo.id});
    // alert(userInfo.id);
    const submittedData = new FormData();
    Object.keys(courseData).forEach(key => {
      submittedData.append(key, courseData[key]);
    });
    axios.post(`${apiUrl}/create-course`, submittedData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      if (res.status === 200){
        localStorage.setItem('course', res.data._id);
        showSuccessNotification("Successfully created")
        navigate(`/subtopic-management/${res.data._id}`);
      }
    }).catch(err => {
      showErrorNotification(err);
    })
  };

  return (
    <>
    {!userInfo ? <CenterLoader2 text="waiting for permission to access page"/> :
    
    <CourseFormContainer theme={theme}>
      <CourseForm onSubmit={handleSubmit} theme={theme} method='post' enctype='multipart/form-data'>
        <FormElement>
          <Label theme={theme}>Title</Label>
          <Input
            type="text"
            name="course_title"
            value={courseData.course_title}
            onChange={handleChange}
            theme={theme}
          />
          {errors.title && <ErrorText>{errors.title}</ErrorText>}
        </FormElement>
        <FormElement>
          <Label theme={theme}>Description</Label>
          <RichTextEditor
            name="description"
            value={courseData.description}
            onChange={handleChange}
            theme={theme}
          />
          {errors.description && <ErrorText>{errors.description}</ErrorText>}
        </FormElement>
        <FormElement>
          <Label theme={theme}>Highlights</Label>
          <RichTextEditor
            name="highlights"
            value={courseData.highlights}
            onChange={handleChange}
            theme={theme}
          />
          {errors.highlights && <ErrorText>{errors.highlights}</ErrorText>}
        </FormElement>
        <FormElement>
          <Label theme={theme}>Requirements</Label>
          <RichTextEditor
             name="requirements"
             value={courseData.requirements}
             onChange={handleChange}
             theme={theme}
          />
          {errors.requirements && <ErrorText>{errors.requirements}</ErrorText>}
        </FormElement>
        <FormElement>
          <Label theme={theme}>Mode</Label>
          <Select
            name="mode"
            value={courseData.mode}
            onChange={handleChange}
            theme={theme}
          >
            <option value="">Select Mode</option>
            <option value="group online">Group online</option>
            <option value="personal online">Personal Online</option>
            <option value="pre-recorded">Pre-recorded</option>
            <option value="offline">Offline</option>
          </Select>
          {errors.mode && <ErrorText>{errors.mode}</ErrorText>}
        </FormElement>
        <FormElement>
          <Label theme={theme}>Price</Label>
          <Input
            type="number"
            name="price"
            value={courseData.price}
            onChange={handleChange}
            theme={theme}
          />
          {errors.title && <ErrorText>{errors.title}</ErrorText>}
        </FormElement>
        <FormElement>
          <Label theme={theme}>Intro Image</Label>
          <ImageInput
            type="file"
            name="course_image"
            onChange={handleImageChange}
            theme={theme}
            accept='image/png,image/jpg,image/jpeg,image/webp'
            title='only accept jpep and png files'
          />

          {errors.mode && <ErrorText>{errors.mode}</ErrorText>}
        </FormElement>
        <FormElement>
          <Label theme={theme}>Intro Video</Label>
          <VideoInput
            type='file'
            name="course_video"
            onChange={handleVideoChange}
            theme={theme}
            accept='video/avi,video/mp4'
            title='only accept avi and mp4 video'
          />

          {errors.mode && <ErrorText>{errors.mode}</ErrorText>}
        </FormElement>
        <FormElement>
          <CheckboxContainer>
            <Checkbox
              type="checkbox"
              name="certificate"
              checked={courseData.certificate}
              onChange={handleCheckboxChange}
              theme={theme}
            />
            <Label theme={theme}>Certificate</Label>
          </CheckboxContainer>
          {errors.certificate && <ErrorText>{errors.certificate}</ErrorText>}
        </FormElement>
        <SubmitButton type="submit" theme={theme}>Create Course</SubmitButton>
      </CourseForm>

      <Navigation prevPage={prevPage} nextPage={nextPage} />
    </CourseFormContainer>
  }

    </>

  );
};

const CourseFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: ${props => (props.theme === 'light' ? '#fff' : '#2c2c2c')};
  border-radius: 5px;
  box-shadow: ${props => (props.theme === 'light' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.4)')};
`;

const CourseForm = styled.form`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormElement = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  color: ${props => (props.theme === 'light' ? '#000' : '#fff')};
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid ${props => (props.theme === 'light' ? '#ccc' : '#444')};
  border-radius: 5px;
  background-color: ${props => (props.theme === 'light' ? '#f9f9f9' : '#3c3c3c')};
  color: ${props => (props.theme === 'light' ? '#000' : '#fff')};
  box-shadow: ${props => (props.theme === 'light' ? '0 2px 4px rgba(0, 0, 0, 0.1)' : '0 2px 4px rgba(0, 0, 0, 0.4)')};
  &:focus {
    border-color: ${props => (props.theme === 'light' ? '#000' : '#fff')};
  }
`;

const Textarea = styled.textarea`
  padding: 10px;
  border: 1px solid ${props => (props.theme === 'light' ? '#ccc' : '#444')};
  border-radius: 5px;
  background-color: ${props => (props.theme === 'light' ? '#f9f9f9' : '#3c3c3c')};
  color: ${props => (props.theme === 'light' ? '#000' : '#fff')};
  box-shadow: ${props => (props.theme === 'light' ? '0 2px 4px rgba(0, 0, 0, 0.1)' : '0 2px 4px rgba(0, 0, 0, 0.4)')};
  &:focus {
    border-color: ${props => (props.theme === 'light' ? '#000' : '#fff')};
  }
`;

const Select = styled.select`
  padding: 10px;
  border: 1px solid ${props => (props.theme === 'light' ? '#ccc' : '#444')};
  border-radius: 5px;
  background-color: ${props => (props.theme === 'light' ? '#f9f9f9' : '#3c3c3c')};
  color: ${props => (props.theme === 'light' ? '#000' : '#fff')};
  box-shadow: ${props => (props.theme === 'light' ? '0 2px 4px rgba(0, 0, 0, 0.1)' : '0 2px 4px rgba(0, 0, 0, 0.4)')};
  &:focus {
    border-color: ${props => (props.theme === 'light' ? '#000' : '#fff')};
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: ${props => (props.theme === 'light' ? '#007bff' : '#0056b3')};
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  box-shadow: ${props => (props.theme === 'light' ? '0 2px 4px rgba(0, 0, 0, 0.1)' : '0 2px 4px rgba(0, 0, 0, 0.4)')};
  &:hover {
    background-color: ${props => (props.theme === 'light' ? '#0056b3' : '#007bff')};
  }
`;

const ErrorText = styled.span`
  color: #ff0000;
  font-size: 12px;
`;

const FileInput = styled.input`
  padding: 10px;
  border: 1px solid ${props => (props.theme === 'light' ? '#ccc' : '#444')};
  border-radius: 5px;
  background-color: ${props => (props.theme === 'light' ? '#f9f9f9' : '#3c3c3c')};
  color: ${props => (props.theme === 'light' ? '#000' : '#fff')};
  box-shadow: ${props => (props.theme === 'light' ? '0 2px 4px rgba(0, 0, 0, 0.1)' : '0 2px 4px rgba(0, 0, 0, 0.4)')};
  &:focus {
    border-color: ${props => (props.theme === 'light' ? '#000' : '#fff')};
  }
`;

const ImageInput = styled(FileInput)`
  ::file-selector-button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: ${props => (props.theme === 'light' ? '#007bff' : '#0056b3')};
    color: #fff;
    cursor: pointer;
    &:hover {
      background-color: ${props => (props.theme === 'light' ? '#0056b3' : '#007bff')};
    }
  }
`;

const VideoInput = styled(FileInput)`
  ::file-selector-button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: ${props => (props.theme === 'light' ? '#28a745' : '#218838')};
    color: #fff;
    cursor: pointer;
    &:hover {
      background-color: ${props => (props.theme === 'light' ? '#218838' : '#28a745')};
    }
  }
`;

export default CourseCreationPage;
