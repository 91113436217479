import React, { useState, useEffect } from "react";
import styled from "styled-components";
import SectionHeading from "../components/Common/SectionHeading";
import { useTheme } from '../contexts/ThemeContext';
import axios from 'axios';
import { showErrorNotification, showSuccessNotification } from '../components/Layouts/Notification';
import CenteredLoader from "../components/Layouts/CenteredLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faRobot, faMobile, faGlobe, faCodeCompare, faComputer, faBrain } from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from "../apiUrl";
// Data for Courses and Gallery
const courses = [
  {
    title: "Coding and Robotics",
    icon: faRobot,
  },
  {
    title: "Mobile App Development and Robotics",
    icon: faMobile,
  },
  {
    title: "Web Development",
    icon: faGlobe,
  },
  {
    title: "Python Programming",
    icon: faCodeCompare,
  },
  {
    title: "Web Design for Kids",
    icon: faComputer,
  },
  {
    title: "Computer Vision and Artificial Intelligence",
    icon: faBrain,
  },

  {
    title: "Javascript Programming",
    icon: faBrain,
  },
  {
    title: "Visual Basic (VB)",
    icon: faBrain,
  },
];

// Objective Data
const objectives = [
  {
    title: "Enhance Digital Literacy",
    icon: faRobot,
  },
  {
    title: "Promote Coding and Robotics",
    icon: faMobile,
  },
  {
    title: "Encourage Creativity and Innovation",
    icon: faGlobe,
  },
  {
    title: "Prepare for Future Careers",
    icon: faCodeCompare,
  }
];

// Video Data
const galleryVideos = [
  "https://www.youtube.com/embed/hn-uuBtRXKg",
  "https://www.youtube.com/embed/0EfzC85F1Rc",
  "https://www.youtube.com/embed/YuNjwIA_5xc",
  "https://youtube.com/embed/iwEfQUpyrig",
];

// ServiceCard Component
const ServiceCard = ({ course }) => {
  const { theme } = useTheme();
  return (
    <ServiceCardBox theme={theme}>
      <FontAwesomeIcon icon={course.icon} size="3x" style={{ color: 'red' }} />
      <Header3>{course.title}</Header3>
    </ServiceCardBox>
  );
};

// ObjectiveList Component
const ObjectiveList = () => {
  const { theme } = useTheme();
  return (
    <ObjectiveBox theme={theme}>
        {objectives.map((objective, index) => (
          <ServiceCardBox theme={theme}>
          <FontAwesomeIcon icon={objective.icon} size="3x" style={{ color: 'red' }} />
          <Header3>{objective.title}</Header3>
        </ServiceCardBox>
        ))}
    </ObjectiveBox>
  );
};

const ProposalForm = () => {
  const { theme } = useTheme();
  const [formData, setFormData] = useState({
    schoolName: '',
    email: '',
    contactPerson: ''
  });

  // Handle form data changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value.trim() // Sanitize input by trimming extra spaces
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send sanitized form data to the endpoint
      await axios.post(`${apiUrl}/school-program`, formData).then(response => {
      showSuccessNotification(response.status);
        // Open the received file URL in a new browser tab
      const { proposalUrl } = response.data;
      const viewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(proposalUrl)}&embedded=true`;
      window.open(viewerUrl, '_blank');
      }).catch(err => {
        showErrorNotification(err);
      });
      
    } catch (error) {
      console.error('Error submitting the form:', error);
    }
  };

  return (
    <FormBox theme={theme}>
      <form onSubmit={handleSubmit} method="post">
        <label htmlFor="schoolName">School Name:</label>
        <input
          type="text"
          id="schoolName"
          name="schoolName"
          value={formData.schoolName}
          onChange={handleChange}
          required
        />

        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label htmlFor="contactPerson">Phone No</label>
        <input
          type="text"
          id="contactPerson"
          name="contactPerson"
          value={formData.contactPerson}
          onChange={handleChange}
          required
        />

        <button type="submit">Request Proposal</button>
      </form>
    </FormBox>
  );
};

// Gallery Component
const Gallery = () => {
  return (
    <GalleryBox>
      {galleryVideos.map((video, index) => (
        <VideoIframe key={index} src={video} title={`Gallery Video ${index}`} frameBorder="0" allowFullScreen></VideoIframe>
      ))}
    </GalleryBox>
  );
};

// FloatingChat Component
const FloatingChat = () => {
  return (
    <ChatButton onClick={() => window.open("https://wa.me/2348179776605", "_blank")}>
      Chat with us
    </ChatButton>
  );
};

// Main Page Component
export default function CodingRoboticsPage() {
  const { theme } = useTheme();
  const [loading, setLoading] = useState(true);
  const [schoolCount, setSchoolCount] = useState(0);

  useEffect(() => {
    axios.get(`${apiUrl}/get-all-courses`).then(response => {
      setLoading(false);
      setSchoolCount(10); // Example school count
    }).catch(err => {
      showErrorNotification(`Error fetching data: ${err}`);
    });
  }, []);

  if (loading) {
    return <CenteredLoader />;
  }

  return (
    <PageContainer theme={theme}>
      <iframe width="100%" height="400" src="https://www.youtube.com/embed/ueeZam7RVtM" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Promo Video"></iframe>

      <SectionHeading text='Courses Offered' />
      <ServiceList>
        {courses.map((course, index) => (
          <ServiceCard key={index} course={course} />
        ))}
      </ServiceList>

      <SectionHeading text='Objectives' />
      <ObjectiveList />

      <SectionHeading text='Student Project' />
      <Gallery />

      <SectionHeading text='Request a Proposal' />
      <ProposalForm />

      <FloatingChat />
    </PageContainer>
  );
}

// Styled Components
const PageContainer = styled.div`
  background-color: ${({ theme }) => theme === "light" ? "#ffffff" : "#333333"};
  color: ${({ theme }) => theme === "light" ? "#000000" : "#ffffff"};
  padding: 20px;

@media (max-width : 960px){
padding: 10px;
}
`;

const ServiceList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width : 100%;
  gap: 20px;
  justify-content : center;

@media (max-width : 960px){
gap: 10px;
}
`;

const ServiceCardBox = styled.div`
  background-color: ${({ theme }) => theme === "light" ? "#f9f9f9" : "#444444"};
  border-radius: 10px;
  text-align : center;
  padding: 20px;
  width: 22%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 250px;

  @media screen and (max-width: 800px) {
    width: 40%;
    height: 200px

  }

`;

const Header3 = styled.div`
font-size: 1.1rem;
margin-bottom: 10px;
font-weight : bold;


@media (max-width : 960px) {
font-size: 0.7rem;
}
`
const ObjectiveBox = styled.div`
  padding: 20px;
  border-radius: 10px;
  display : flex;
  flex-wrap : wrap;
  justify-content : center;
  gap : 20px;
  
`;

const FormBox = styled.div`
  padding : 10px 15%;
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  label {
    font-weight: bold;
  }
  input {
    padding: 10px;
    border: 1px solid ${({ theme }) => theme === "light" ? "#cccccc" : "#555555"};
    border-radius: 5px;
  }
  button {
    padding: 10px;
    background-color: ${({ theme }) => theme === "light" ? "#007BFF" : "#0056b3"};
    color: #ffffff;
    cursor: pointer;
  }
`;

const GalleryBox = styled.div`
  display: flex;
  flex-wrap : wrap;
  gap: 20px;
  padding : 20px;
  text-align : center;
  width : 100%;

`;

const VideoIframe = styled.iframe`
    width: 30%;
    height: 300px;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    @media (max-width : 960px) {
    width : 45%;
    height: 200px;
    }
`
const ChatButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 5px 10px;
  background-color: #25D366;
  color: #ffffff;
  cursor: pointer;
`;
