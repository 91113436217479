// CenteredLoader.js
import React from 'react';
import { TailSpin } from 'react-loader-spinner';

const CenteredLoader = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255)', // transparent background
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100, // high z-index
      }}
    >
      <TailSpin
        type="TailSpin"
        color="#00BFFF"
        height={200} // large size
        width={200}
        speed={100} // fast spinning speed
      />
    </div>
  );
};

export default CenteredLoader;