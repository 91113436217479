import React, { useState, useEffect, useContext } from "react";
import BannerCarousel from "../components/Common/BannerCarousel";
import CourseCard from '../components/Courses/CourseCard';
import styled from "styled-components";
import SectionHeading from "../components/Common/SectionHeading";
import axios from 'axios';
import { showErrorNotification, showSuccessNotification } from '../components/Layouts/Notification';
import CenteredLoader from "../components/Layouts/CenteredLoader";
import { useTheme } from '../contexts/ThemeContext';
import { apiUrl } from "../apiUrl";

axios.defaults.withCredentials = true;

export default function HomePage() {
const [courseData, setCourseData] = useState([]);
const [pageNumber, setPageNumber] = useState(8);
const [loading, setLoading] = useState(true);
const [ready, setReady] = useState(true);
const { theme } = useTheme();
const [groupCourse, setGroupCourse] = useState([]);
const [privateCourse, setPrivateCourse] = useState([]);

const sort_image_url = (list_of_urls) => {
    return list_of_urls.map((value) => {
      const sliced_image = value.course_image.split("\\");
      const extracted_image = sliced_image[sliced_image.length - 1];
      return { ...value, course_image: extracted_image };
    });
  };

useEffect(() => {
    axios.get(`${apiUrl}/get-all-courses?pagenumber=${pageNumber}`, {
      withCredentials: true,
    }).then(response => {
    const updated_array = sort_image_url(response.data);
    // alert(Object.keys(updated_array[0]));
    const groupCourse = updated_array.filter((course) => course.mode === "group online");
    const privateCourse = updated_array.filter((course) => course.mode === "personal online");
    setCourseData(updated_array);
    setPrivateCourse(privateCourse);
    setGroupCourse(groupCourse);

    setLoading(false);
    }).catch(err => {
      showErrorNotification(`error fetching Data : ${err}`);;
    });
    },[pageNumber]);
    

return (
<div>
{loading ? (
      <div>
  <CenteredLoader />
      </div>) : (
<>
<BannerCarousel theme={theme}/>
<SectionHeading text='Group Online Live Classes' />
<ListingBox>
      {groupCourse.map((course, index) => (
        <CourseCard key={index} course={course} />
      ))}
</ListingBox>
<SectionHeading text='Get a Personal Online Tutor in' />
<ListingBox>
      {courseData.map((course, index) => (
        <CourseCard key={index} course={course} />
      ))}
</ListingBox>
</>
)
}
</div>
);
}

const ListingBox = styled.div`
display: flex;
flex-wrap: wrap;
boxSizing : border-box;
padding : 10px;
margin-top : 0;
`;