import React from 'react';
import styled from 'styled-components';
import {useTheme} from '../../contexts/ThemeContext'

function SectionHeading(props) {
const {theme} = useTheme();

return (
<HeadingContainer theme={theme}>
    <Heading>{props.text}</Heading>
</HeadingContainer>
)
}

export default SectionHeading;

const HeadingContainer = styled.div`
color : ${(props) => props.theme === 'light' ? '#000' : '#fff'};
text-align : center;
margin-top : 30px;
margin-bottom : 0;
`;

const Heading = styled.p`
font-weight : bold;
font-size : 35px;

@media (max-width: 768px) {
font-size : 20px;
}
`;