import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import RichTextEditor from '../components/Common/RichTextEditor';
import { sanitizeFormData } from '../utils/sanitizationUtility';
import { uploadFormData } from '../utils/axiosFunction';
import { UserContext } from '../contexts/userContext'; // Assuming there's a UserContext
import {useSelector, useDispatch} from 'react-redux';
import axios from 'axios';
import { apiUrl } from '../apiUrl';
import { showErrorNotification, showSuccessNotification } from '../components/Layouts/Notification';
import CenterLoader2 from "../components/Layouts/CenteredLoader2";


const SubtopicManagementPage = () => {
  const { theme } = useTheme();
  const { courseid: urlCourse } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user.user);
  const [userInfo, setUserInfo] = useState();
  const [hasSubmitted, setHasSubmitted] = useState('');
  const [course, setCourse] = useState(urlCourse || localStorage.getItem('course'));
  const [subtopics, setSubtopics] = useState(() => JSON.parse(localStorage.getItem('subtopics')) || {});
  const [subtopicDescription, setSubtopicDescription] = useState(() => JSON.parse(localStorage.getItem('subtopicDescription')) || {});
  const [contentData, setContentData] = useState(() => JSON.parse(localStorage.getItem('contentData')) || []);
  const [videoData, setVideoData] = useState(() => JSON.parse(localStorage.getItem('videoData')) || []);
  const [subtopicList, setSubtopicList] = useState([]);
  const [courseDetails, setCourseDetails] = useState();
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(()=> {
    if (user && (user !== undefined && user !== null)) {
      // alert(user);
      setUserInfo(user || JSON.parse(localStorage.getItem('user')));
    } else {
      setUserInfo(JSON.parse(localStorage.getItem('user')));
    }

    if(!course) {
      setCourse(localStorage.getItem('course'));
    }
  }, [user, userInfo, course]);


  useEffect(()=> {
    try {
      axios.get(`${apiUrl}/get-subtopics?courseId=${course}`)
      .then(res => {
        setSubtopicList(res.data);
        showSuccessNotification(res.status);
        // alert("success");
      }).catch(err => {
        showErrorNotification(err);
      });
    } catch (error) {
      console.error('Error fetching subtopics:', error);
    }
  }, [course]);

  useEffect(() => {
    const checkAuthorization = async () => {
      try {
        await axios.get(`${apiUrl}/course?id=${course}`, {
          withCredentials: true,
        }).then(res => {
          showSuccessNotification(res.data)
          if (res.data.author !== userInfo.id) {
            navigate(-1);
          } else {
            setIsAuthorized(true);
          }
        }).catch(err => {
          showErrorNotification(err);
        });
        
      } catch (error) {
        // alert('Authorization error:', error);
        console.error('Authorization error:', error);
      }
    };
    
    const fetchCourseDetails = async () => {
      try {
      axios.get(`${apiUrl}/course?id=${course}`)
      .then(res => {
        setCourseDetails(res.data);
      }).catch(err => {
        showErrorNotification(err);
      });
    } catch (e) {
      console.log(e);
    }
    }
    
    if(!isAuthorized) {
    checkAuthorization();
    }

    if(!courseDetails) {
      fetchCourseDetails(); // fetching course Details
    }
  }, [courseDetails, isAuthorized, navigate, userInfo, course]);

  useEffect(() => {
    const saveToLocalStorage = () => {
      localStorage.setItem('subtopics', JSON.stringify(subtopics));
      localStorage.setItem('subtopicDescription', JSON.stringify(subtopicDescription));
      localStorage.setItem('contentData', JSON.stringify(contentData));
      localStorage.setItem('videoData', JSON.stringify(videoData));
    };

    const interval = setInterval(saveToLocalStorage, 10000);
    return () => clearInterval(interval);
  }, [subtopics, contentData, videoData, subtopicDescription]);

  const fetchSubtopics = () => {
    try {
      axios.get(`${apiUrl}/get-subtopics?courseId=${course}`)
      .then(res => {
        setSubtopicList(res.data);
        showSuccessNotification(res.status);
        // alert("success");
      }).catch(err => {
        showErrorNotification(err);
      });
    } catch (error) {
      console.error('Error fetching subtopics:', error);
    }
  }

  const handleInputChange = (setState) => (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    setHasSubmitted('');
  };

  const handleArrayChange = (setState, state) => (index, field, value) => {
    const newState = [...state];
    newState[index][field] = value;
    setState(newState);
    setHasSubmitted('');
  };

  const handleAddItem = (setState, itemTemplate) => () => {
    setState((prevState) => [...prevState, itemTemplate]);
    setHasSubmitted('');
  };

  const handleDeleteItem = (setState, state) => (index) => {
    const newState = state.filter((_, i) => i !== index);
    setState(newState);
    setHasSubmitted('');
  };

  const handleSubmit = async () => {
    const all_topics = subtopicList.map((topic) => topic.topic_title);
    // alert(all_topics.includes(subtopics.topic_title));
    if ((subtopics.topic_title !== "") && (all_topics.includes(subtopics.topic_title) === false)) {
      const formData = new FormData();
      formData.append('sanitizedData', JSON.stringify(sanitizeFormData({ course, subtopics, contentData, videoData, subtopicDescription })));
  
      contentData.forEach((content, index) => {
        formData.append('contents', sanitizeFormData(content.file), `content_${index}`);
      });
  
      videoData.forEach((video, index) => {
        formData.append('videos', sanitizeFormData(video.file), `video_${index}`);
      });
  
      try {
        await axios.post(`${apiUrl}/create-suptopics`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then((res) => {
          console.log(res);
          showSuccessNotification(res.status);
          setSubtopics({});
          setSubtopicDescription([]);
          setContentData([]);
          setVideoData([]);
          setHasSubmitted('Data Successfully submitted');
          setHasSubmitted('');
          fetchSubtopics();
        }).catch((err) => {
          console.log('Error submitting Data');
          setHasSubmitted('Error Submitting Data' + err.message);
          alert('Error Submitting Data' + err.message);
        });
      } catch (error) {
        console.error('Submission error:', error);
      }
    } else {
      setHasSubmitted("Topic already exist or empty");
      showErrorNotification("Topics already exist, check carefully")
    }


  };

  const handleEditSubtopic = (id) => {
    // Handle edit subtopic functionality
  };

  const handleDeleteSubtopic = async (id) => {
    try {
      await axios.delete(`${apiUrl}/delete-subtopic?id=${id}`);
      // fetchSubtopics(); // Refresh the subtopics after deletion
    } catch (error) {
      console.error('Error deleting subtopic:', error);
    }
  };

  return (
    <>
    {!(userInfo && course && courseDetails && subtopicList) ? 
      
      <CenterLoader2 text="loading page" /> :

    <SubtopicManagementContainer theme={theme}>
      <Section>
        <CourseName theme={theme}>Course : {courseDetails.course_title}</CourseName>
        <Label theme={theme}>{hasSubmitted}</Label>
      </Section>

      <Section>
        <SubtopicInputContainer>
          <FormElement>
            <Label theme={theme}>Topic Title</Label>
            <SubTopicInput
              name='topic_title'
              type="text"
              value={subtopics.topic_title || ''}
              onChange={handleInputChange(setSubtopics)}
              placeholder="Topic name"
              theme={theme}
              required={true}
            />
          </FormElement>
          <FormElement>
            <Label theme={theme}>Topic Description</Label>
            <RichTextEditor
              name="topic_description"
              value={subtopicDescription.topic_description || ''}
              onChange={handleInputChange(setSubtopicDescription)}
              theme={theme}
            />
          </FormElement>
        </SubtopicInputContainer>
      </Section>

      <Section>
        <h2>Content</h2>
        <AddButton onClick={handleAddItem(setContentData, { title: '', file: null })} theme={theme}>Add Content</AddButton>
        {contentData.map((content, index) => (
          <ContentItem key={index}>
            <Input
              type="text"
              value={content.title}
              onChange={(e) => handleArrayChange(setContentData, contentData)(index, 'title', e.target.value)}
              placeholder="Content Title"
              theme={theme}
            />
            <Input
              type="file"
              onChange={(e) => handleArrayChange(setContentData, contentData)(index, 'file', e.target.files[0])}
              theme={theme}
            />
            <DeleteButton onClick={() => handleDeleteItem(setContentData, contentData)(index)} theme={theme}>Delete</DeleteButton>
          </ContentItem>
        ))}
      </Section>

      <Section>
        <h2>Videos</h2>
        <AddButton onClick={handleAddItem(setVideoData, { title: '', file: null })} theme={theme}>Add Video</AddButton>
        {videoData.map((video, index) => (
          <VideoItem key={index}>
            <Input
              type="text"
              value={video.title}
              onChange={(e) => handleArrayChange(setVideoData, videoData)(index, 'title', e.target.value)}
              placeholder="Video Title"
              theme={theme}

            />
            <Input
              type="file"
              onChange={(e) => handleArrayChange(setVideoData, videoData)(index, 'file', e.target.files[0])}
              theme={theme}
            />
            <DeleteButton onClick={() => handleDeleteItem(setVideoData, videoData)(index)} theme={theme}>Delete</DeleteButton>
          </VideoItem>
        ))}
      </Section>

      <SubmitButton onClick={handleSubmit} theme={theme}>Submit All</SubmitButton>

      {/* Table displaying subtopics */}

      <Section>
        <h2>Existing Subtopics</h2>
        <TableContainer>
          <table>
            <thead>
              <tr>
                <th>Title</th>
                <th>Description</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {subtopicList.map((subtopic) => (
                <tr key={subtopic._id}>
                  <td>{subtopic.topic_title}</td>
                  <td><div dangerouslySetInnerHTML={{__html : subtopic.topic_description}}/></td>
                  <td>
                    <EditButton onClick={() => handleEditSubtopic(subtopic._id)} theme={theme}>Edit</EditButton>
                  </td>
                  <td>
                    <DeleteButton onClick={() => handleDeleteSubtopic(subtopic._id)} theme={theme}>Delete</DeleteButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </Section>

    </SubtopicManagementContainer>

  }
</>
  );
};

// Styled components
const SubtopicManagementContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: ${(props) => (props.theme === 'light' ? '#fff' : '#2c2c2c')};
  border-radius: 5px;
  color: ${props => (props.theme === 'light' ? '#000' : '#fff')};
  box-shadow: ${(props) => (props.theme === 'light' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.4)')};
`;

const Section = styled.div`
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
`;

const TableContainer = styled.div`
  margin-top: 20px;
  max-height: 400px%;
  table {
    width: 100%;
    border-collapse: collapse;
  }
  th, td {
    padding: 10px;
    border: 1px solid #ddd;
  }
`;

const EditButton = styled.button`
  padding: 5px 10px;
  background-color: ${(props) => (props.theme === 'light' ? '#007bff' : '#0056b3')};
  color: #fff;
  border: none;
  cursor: pointer;
`;

const SubtopicInputContainer = styled.div``;

const Input = styled.input`
  padding: 10px;
  border: 1px solid ${props => (props.theme === 'light' ? '#ccc' : '#444')};
  border-radius: 5px;
  background-color: ${props => (props.theme === 'light' ? '#f9f9f9' : '#3c3c3c')};
  color: ${props => (props.theme === 'light' ? '#000' : '#fff')};
  box-shadow: ${props => (props.theme === 'light' ? '0 2px 4px rgba(0, 0, 0, 0.1)' : '0 2px 4px rgba(0, 0, 0, 0.4)')};
  &:focus {
    border-color: ${props => (props.theme === 'light' ? '#000' : '#fff')};
  }
`;

const SubTopicInput = styled(Input)``;

const AddButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: ${props => (props.theme === 'light' ? '#007bff' : '#0056b3')};
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  box-shadow: ${props => (props.theme === 'light' ? '0 2px 4px rgba(0, 0, 0, 0.1)' : '0 2px 4px rgba(0, 0, 0, 0.4)')};
  &:hover {
    background-color: ${props => (props.theme === 'light' ? '#0056b3' : '#007bff')};
  }
`;

const ContentItem = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const VideoItem = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const DeleteButton = styled.button`
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #ff4d4d;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: #ff1a1a;
  }
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  border: none;
  margin: 10px 0;
  border-radius: 5px;
  background-color: ${props => (props.theme === 'light' ? '#28a745' : '#218838')};
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  box-shadow: ${props => (props.theme === 'light' ? '0 2px 4px rgba(0, 0, 0, 0.1)' : '0 2px 4px rgba(0, 0, 0, 0.4)')};
  &:hover {
    background-color: ${props => (props.theme === 'light' ? '#218838' : '#28a745')};
  }
`;

const FormElement = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  color: ${props => (props.theme === 'light' ? '#000' : '#fff')};
  margin-bottom: 5px;
`;

const CourseName = styled.h3`
font-size: 1.2rem;
color: ${props => (props.theme === 'light' ? '#000' : '#fff')};

@media (max-width: 968px) {
font-size : 0.9rem;
}
`;

export default SubtopicManagementPage;
