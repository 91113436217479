import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const banners = [
  {
    image: "https://codeignitefiles.s3.us-east-1.amazonaws.com/codingholidaysmall.jpg",
    text: 'This is the first banner text',
  },
  {
    image: "https://codeignitefiles.s3.us-east-1.amazonaws.com/codingholidaysmall.jpg",
    text: 'This is the second banner text',
  },
  {
    image: "https://codeignitefiles.s3.us-east-1.amazonaws.com/codingholidaysmall.jpg",
    text: 'This is the third banner text',
  },
];

const BannerCarousel = ({ theme }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    appendDots: (dots) => (
      <DotsWrapper>
        {dots}
      </DotsWrapper>
    ),
    customPaging: () => <Dot />,
  };

  return (
    <SliderWrapper theme={theme}>
      <Slider {...settings}>
        {banners.map((banner, index) => (
          <Slide key={index}>
            <ImageWrapper>
              <Image src={banner.image} alt={`Banner ${index + 1}`} />
            </ImageWrapper>
            <Text theme={theme}>{banner.text}</Text>
            <Button>Learn More</Button>
          </Slide>
        ))}
      </Slider>
    </SliderWrapper>
  );
};

const PrevArrow = ({ onClick }) => (
  <Arrow className="prev" onClick={onClick}>
    <FaArrowLeft />
  </Arrow>
);

const NextArrow = ({ onClick }) => (
  <Arrow className="next" onClick={onClick}>
    <FaArrowRight />
  </Arrow>
);

const SliderWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  box-sizing: border-box;
  background-color: ${props => props.theme === 'light' ? '#fff' : '#333'};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 20px;
  }
  @media (max-width: 480px) {
    padding: 10px;
  }
`;

const Slide = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const ImageWrapper = styled.div`
  width: 100%;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: cover;

  @media (max-width: 768px) {
    max-height: 300px;
  }
  @media (max-width: 480px) {
    max-height: 200px;
  }
`;

const Text = styled.div`
  margin: 10px 0;
  font-size: 1.2rem;
  color: ${props => props.theme === 'light' ? '#000' : '#fff'};

  @media (max-width: 768px) {
    font-size: 1rem;
  }
  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

const Button = styled.div`
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    background-color: #0056b3;
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;

  &.prev {
    left: 10px;
  }

  &.next {
    right: 10px;
  }

  svg {
    color: #fff;
    font-size: 1.5rem;
  }
`;

const DotsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-top: 15px;
  }
  @media (max-width: 480px) {
    margin-top: 10px;
  }
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #007bff;
  margin: 0 5px;

  @media (max-width: 768px) {
    width: 8px;
    height: 8px;
    margin: 0 4px;
  }
  @media (max-width: 480px) {
    width: 6px;
    height: 6px;
    margin: 0 3px;
  }
`;

export default BannerCarousel;
