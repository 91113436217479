import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import Navigation from '../components/Courses/CourseNavigation'

const ContentUploadPage = ({ subtopicId, prevPage, nextPage }) => {
  const { theme } = useTheme();
  const [videoData, setVideoData] = useState({ videoTitle: '', videoUrl: '', videoDuration: '' });
  const [materialData, setMaterialData] = useState({ materialTitle: '', materialUrl: '' });
  const [errors, setErrors] = useState({});

  const handleVideoChange = (e) => {
    const { name, value } = e.target;
    setVideoData({
      ...videoData,
      [name]: value
    });
  };

  const handleMaterialChange = (e) => {
    const { name, value } = e.target;
    setMaterialData({
      ...materialData,
      [name]: value
    });
  };

  const handleVideoSubmit = (e) => {
    e.preventDefault();
    // Add validation and video upload logic here
  };

  const handleMaterialSubmit = (e) => {
    e.preventDefault();
    // Add validation and material upload logic here
  };

  return (
    <ContentUploadContainer theme={theme}>
      <UploadForm onSubmit={handleVideoSubmit} theme={theme}>
        <FormElement>
          <Label theme={theme}>Video Title</Label>
          <Input
            type="text"
            name="videoTitle"
            value={videoData.videoTitle}
            onChange={handleVideoChange}
            theme={theme}
          />
          {errors.videoTitle && <ErrorText>{errors.videoTitle}</ErrorText>}
        </FormElement>
        <FormElement>
          <Label theme={theme}>Video URL</Label>
          <Input
            type="text"
            name="videoUrl"
            value={videoData.videoUrl}
            onChange={handleVideoChange}
            theme={theme}
          />
          {errors.videoUrl && <ErrorText>{errors.videoUrl}</ErrorText>}
        </FormElement>
        <FormElement>
          <Label theme={theme}>Video Duration</Label>
          <Input
            type="text"
            name="videoDuration"
            value={videoData.videoDuration}
            onChange={handleVideoChange}
            theme={theme}
          />
          {errors.videoDuration && <ErrorText>{errors.videoDuration}</ErrorText>}
        </FormElement>
        <SubmitButton type="submit" theme={theme}>Upload Video</SubmitButton>
      </UploadForm>

      <UploadForm onSubmit={handleMaterialSubmit} theme={theme}>
        <FormElement>
          <Label theme={theme}>Material Title</Label>
          <Input
            type="text"
            name="materialTitle"
            value={materialData.materialTitle}
            onChange={handleMaterialChange}
            theme={theme}
          />
          {errors.materialTitle && <ErrorText>{errors.materialTitle}</ErrorText>}
        </FormElement>
        <FormElement>
          <Label theme={theme}>Material URL</Label>
          <Input
            type="text"
            name="materialUrl"
            value={materialData.materialUrl}
            onChange={handleMaterialChange}
            theme={theme}
          />
          {errors.materialUrl && <ErrorText>{errors.materialUrl}</ErrorText>}
        </FormElement>
        <SubmitButton type="submit" theme={theme}>Upload Material</SubmitButton>

      </UploadForm>

      <Navigation prevPage={prevPage} nextPage={nextPage} />
    </ContentUploadContainer>
  );
};

const ContentUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: ${props => (props.theme === 'light' ? '#fff' : '#2c2c2c')};
  border-radius: 5px;
  box-shadow: ${props => (props.theme === 'light' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.4)')};
`;

const UploadForm = styled.form`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
`;

const FormElement = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  color: ${props => (props.theme === 'light' ? '#000' : '#fff')};
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid ${props => (props.theme === 'light' ? '#ccc' : '#444')};
  border-radius: 5px;
  background-color: ${props => (props.theme === 'light' ? '#f9f9f9' : '#3c3c3c')};
  color: ${props => (props.theme === 'light' ? '#000' : '#fff')};
  box-shadow: ${props => (props.theme === 'light' ? '0 2px 4px rgba(0, 0, 0, 0.1)' : '0 2px 4px rgba(0, 0, 0, 0.4)')};
  &:focus {
    border-color: ${props => (props.theme === 'light' ? '#000' : '#fff')};
  }
`;

const SubmitButton = styled.button`
  padding: 10px;
  background-color: ${props => (props.theme === 'light' ? '#007bff' : '#0069d9')};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${props => (props.theme === 'light' ? '#0056b3' : '#0056b3')};
  }
`;

const ErrorText = styled.div`
  color: red;
  margin-top: 5px;
`;

export default ContentUploadPage;
