import React, { useState } from 'react';
import styled from 'styled-components';
import { FaFacebook, FaTwitter, FaTiktok, FaInstagram, FaEdit, FaTrash, FaEye, FaEyeSlash, FaUser, FaCopy } from 'react-icons/fa';
import { useTheme } from '../contexts/ThemeContext';

const ReferralDashboard = () => {
  const { theme } = useTheme();
  const [showSensitiveInfo, setShowSensitiveInfo] = useState(false);

  const toggleSensitiveInfo = () => {
    setShowSensitiveInfo(prevState => !prevState);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    // alert('Text copied to clipboard');
  };

  return (
    <DashboardContainer theme={theme}>
      <LeftSection theme={theme}>
        <UserProfile>
          <UserIcon>
            <FaUser size={50} />
          </UserIcon>
          <UserName>John Doe</UserName>
          <AccountInfo>
            <InfoLabel>Account Name:</InfoLabel>
            <InfoValue>John Doe</InfoValue>
          </AccountInfo>
          <AccountInfo>
            <InfoLabel>Account Number:</InfoLabel>
            <InfoValue>{showSensitiveInfo ? '123456789' : '*********'}</InfoValue>
            <ToggleInfoButton onClick={toggleSensitiveInfo}>
              {showSensitiveInfo ? <FaEyeSlash /> : <FaEye />}
            </ToggleInfoButton>
          </AccountInfo>
          <AccountInfo>
            <InfoLabel>Bank:</InfoLabel>
            <InfoValue>{showSensitiveInfo ? 'Bank Name' : '****'}</InfoValue>
            <ToggleInfoButton onClick={toggleSensitiveInfo}>
              {showSensitiveInfo ? <FaEyeSlash /> : <FaEye />}
            </ToggleInfoButton>
          </AccountInfo>
          <AccountInfo>
            <InfoLabel>Date Joined:</InfoLabel>
            <InfoValue>01/01/2024</InfoValue>
          </AccountInfo>
          <MenuList>
            <MenuItem>Promotional Material</MenuItem>
            <MenuItem>Payment History</MenuItem>
          </MenuList>
        </UserProfile>
      </LeftSection>
      <RightSection theme={theme}>
        <ReferralSection>
          <SectionTitle>Referrals</SectionTitle>
          <ReferralList>
            <ReferralItem>
              <ReferralName>Referral 1</ReferralName>
              <ReferralDate>01/01/2024</ReferralDate>
            </ReferralItem>
            <ReferralItem>
              <ReferralName>Referral 2</ReferralName>
              <ReferralDate>02/01/2024</ReferralDate>
            </ReferralItem>
          </ReferralList>
        </ReferralSection>
        <EarningsSection>
          <SectionTitle>Earnings</SectionTitle>
          <EarningsTable>
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>01/01/2024</td>
                <td>$50.00</td>
              </tr>
              <tr>
                <td>02/01/2024</td>
                <td>$75.00</td>
              </tr>
            </tbody>
          </EarningsTable>
        </EarningsSection>
        <SocialShareSection>
          <SectionTitle>Share Your Referral Link</SectionTitle>
          <SocialIcons>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
            <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer"><FaTiktok /></a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
          </SocialIcons>
        </SocialShareSection>
        <PromotionalMaterialsSection>
          <SectionTitle>Promotional Materials</SectionTitle>
          <PromoItem>
            <PromoVideo src="promo-video-url" controls />
            <PromoText>
              Referral Video
              <CopyButton onClick={() => handleCopy('Referral Video URL')}>
                <FaCopy />
              </CopyButton>
            </PromoText>
          </PromoItem>
          <PromoItem>
            <PromoImage src="promo-image-url-1" alt="Promo 1" />
            <PromoText>
              Referral Image 1
              <CopyButton onClick={() => handleCopy('Referral Image 1 URL')}>
                <FaCopy />
              </CopyButton>
            </PromoText>
          </PromoItem>
          <PromoItem>
            <PromoImage src="promo-image-url-2" alt="Promo 2" />
            <PromoText>
              Referral Image 2
              <CopyButton onClick={() => handleCopy('Referral Image 2 URL')}>
                <FaCopy />
              </CopyButton>
            </PromoText>
          </PromoItem>
        </PromotionalMaterialsSection>
        <AccountButtons>
          <EditAccountButton>
            <FaEdit /> Edit Account
          </EditAccountButton>
          <DeleteAccountButton>
            <FaTrash /> Delete Account
          </DeleteAccountButton>
        </AccountButtons>
      </RightSection>
    </DashboardContainer>
  );
};

export default ReferralDashboard;

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  background-color: ${props => (props.theme === 'light' ? '#f0f0f0' : '#1c1c1c')};
  color: ${props => (props.theme === 'light' ? '#000' : '#fff')};
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftSection = styled.div`
  flex: 1;
  padding: 20px;
  background-color: ${props => (props.theme === 'light' ? '#fff' : '#2c2c2c')};
  border-radius: 5px;
  box-shadow: ${props => (props.theme === 'light' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.4)')};
  margin-right: 20px;
  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const UserProfile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UserIcon = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.theme === 'light' ? '#e0e0e0' : '#444')};
  border-radius: 50%;
  margin-bottom: 10px;
`;

const UserName = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
`;

const AccountInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

const InfoLabel = styled.div`
  font-weight: bold;
`;

const InfoValue = styled.div``;

const ToggleInfoButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
`;

const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
`;

const MenuItem = styled.li`
  padding: 10px 0;
  cursor: pointer;
  &:hover {
    background-color: ${props => (props.theme === 'light' ? '#e0e0e0' : '#444')};
    color: ${props => (props.theme === 'light' ? '#000' : '#fff')};
  }
`;

const RightSection = styled.div`
  flex: 3;
  padding: 20px;
  text-align : left;
  background-color: ${props => (props.theme === 'light' ? '#fff' : '#2c2c2c')};
  border-radius: 5px;
  box-shadow: ${props => (props.theme === 'light' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.4)')};
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ReferralSection = styled.section`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 20px;
`;

const ReferralList = styled.ul`
  list-style: none;
  padding: 0;
`;

const ReferralItem = styled.li`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 15px;
  background-color: ${props => (props.theme === 'light' ? '#fff' : '#2c2c2c')};
  border-radius: 5px;
  box-shadow: ${props => (props.theme === 'light' ? '0 2px 4px rgba(0, 0, 0, 0.1)' : '0 2px 4px rgba(0, 0, 0, 0.4)')};
`;

const ReferralName = styled.div``;

const ReferralDate = styled.div``;

const EarningsSection = styled.section`
  margin-bottom: 40px;
`;

const EarningsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  th, td {
    padding: 15px;
    border-bottom: 1px solid ${props => (props.theme === 'light' ? '#e0e0e0' : '#444')};
  }
  th {
    background-color: ${props => (props.theme === 'light' ? '#f8f8f8' : '#3c3c3c')};
  }
`;

const SocialShareSection = styled.section`
  margin-bottom: 40px;
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 2rem;
  a {
    color: inherit;
    text-decoration: none;
    border-radius : 50%;
    padding : 5px 15px;
    box-shadow: ${props => (props.theme === 'light' ? '0 2px 4px rgba(0, 0, 0, 0.1)' : '0 2px 4px rgba(0, 0, 0, 0.4)')};
    &:hover {
      opacity: 0.7;
    }
  }
`;

const PromotionalMaterialsSection = styled.section`
  margin-bottom: 40px;
`;

const PromoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const PromoVideo = styled.video`
  width: 100%;
  border-radius: 5px;
`;

const PromoImage = styled.img`
  width: 100%;
  border-radius: 5px;
`;

const PromoText = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const CopyButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  color: inherit;
`;

const AccountButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const EditAccountButton = styled.button`
  flex: 1;
  margin-right: 10px;
  padding: 10px;
  font-size: 1rem;
  font-weight: bold;
  color: ${props => (props.theme === 'light' ? '#000' : '#fff')};
  background-color: ${props => (props.theme === 'light' ? '#e0e0e0' : '#444')};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: ${props => (props.theme === 'light' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.4)')};

  &:hover {
    background-color: ${props => (props.theme === 'light' ? '#ccc' : '#666')};
  }
`;

const DeleteAccountButton = styled.button`
  flex: 1;
  margin-left: 10px;
  padding: 10px;
  font-size: 1rem;
  font-weight: bold;
  color: ${props => (props.theme === 'light' ? '#fff' : '#fff')};
  background-color: ${props => (props.theme === 'light' ? '#ff5a5a' : '#d32f2f')};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: ${props => (props.theme === 'light' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.4)')};

  &:hover {
    background-color: ${props => (props.theme === 'light' ? '#e04141' : '#b71c1c')};
  }
`;
