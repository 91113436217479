import React from 'react';
import styled from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';

const Footer = () => {
  const { theme, toggleTheme } = useTheme();
  
  return (
    <FooterContainer theme={theme}>
      <TopRow>
        <Column>
          <BoldText>Company we partner with</BoldText>
        </Column>
        <Column>
          <PartnerImages>
            <PartnerImage src="https://codeignitefiles.s3.amazonaws.com/microsoft_logo.jpeg" alt="Microsoft" />
            <PartnerImage src="https://codeignitefiles.s3.amazonaws.com/mit+app+inventor.jpg" alt="MIT" />
            <PartnerImage src="https://codeignitefiles.s3.amazonaws.com/tinkercad+log.webp" alt="Tinkercad" />
          </PartnerImages>
        </Column>
      </TopRow>

      <MiddleRow>
        <Section>
          <SectionTitle>Services</SectionTitle>
          <List>
            <ListItem>Software Development</ListItem>
            <ListItem>IT Personal Coaching</ListItem>
            <ListItem>AI Model Development</ListItem>
            <ListItem>Data Science Project</ListItem>
          </List>
        </Section>
        <Section>
          <SectionTitle>Pages</SectionTitle>
          <List>
            <ListItem>Home</ListItem>
            <ListItem>Courses</ListItem>
            <ListItem>Services</ListItem>
            <ListItem>Contact</ListItem>
            <ListItem>Blog</ListItem>
          </List>
        </Section>
        <Section>
          <SectionTitle>More Pages</SectionTitle>
          <List>
            <ListItem>About Us</ListItem>
            <ListItem>Privacy Policy</ListItem>
            <ListItem>Terms of Service</ListItem>
            <ListItem>FAQ</ListItem>
          </List>
        </Section>
      </MiddleRow>

      <BottomRow>
        <Logo src="https://codeignitefiles.s3.amazonaws.com/codeIgnitelogo%403x.png" alt="Company Logo" />
        <Copyright>© CodeIgnite 2024</Copyright>
      </BottomRow>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.footer`
  background-color: ${(props) => props.theme === 'light' ? '#007bff' : '#333'};
  padding: 20px 40px;
  color: ${(props) => props.theme === 'light' ? '#000' : '#fff'};
  padding: 20px 40px;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #444;
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const BoldText = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
`;

const PartnerImages = styled.div`
  display: flex;
  gap: 10px;
`;

const PartnerImage = styled.img`
  height: 40px;
`;

const MiddleRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #444;
`;

const Section = styled.div`
  flex: 1;
  padding: 0 20px;
`;

const SectionTitle = styled.h3`
  margin-bottom: 10px;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  margin-bottom: 5px;
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
`;

const Logo = styled.img`
  height: 40px;
`;

const Copyright = styled.div`
  display: flex;
  align-items: center;
`;
