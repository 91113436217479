import React from 'react';
import styled from 'styled-components';

const CartDropdown = (props) => {
  // Mock data for cart items
  const cartItems = [
    { id: 1, title: 'Course 1', price: 100, thumbnail: '/path/to/thumbnail1.jpg' },
    { id: 2, title: 'Course 2', price: 150, thumbnail: '/path/to/thumbnail2.jpg' },
  ];

  return (
    <DropdownContainer theme={props.theme}>
      {cartItems.map((item) => (
        <CartItem key={item.id}>
          <Thumbnail src={item.thumbnail} alt={item.title} />
          <ItemDetails>
            <Title>{item.title}</Title>
            <Price>${item.price}</Price>
          </ItemDetails>
        </CartItem>
      ))}
    </DropdownContainer>
  );
};

export default CartDropdown;

// Styled Components
const DropdownContainer = styled.div`
  position: absolute;
  top: 40px;
  right: -10px;
  width: 300px;
  background-color: ${(props) => (props.theme === 'light') ? '#f4f4f4' : '#333333'};
  color: ${(props) => (props.theme === 'light') ? '#000' : '#fff'};
  box-shadow: ${(props) => props.theme === 'light' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.5)'};
  border-radius: 15px;
  z-index: 100;
`;

const CartItem = styled.div`
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #f1f1f1;
`;

const Thumbnail = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 5px;
`;

const ItemDetails = styled.div`
  margin-left: 10px;
`;

const Title = styled.h4`
  margin: 0;
`;

const Price = styled.p`
  margin: 0;
  color: #002379;
`;
