import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { Accordion } from 'react-bootstrap';
import { useTheme } from '../contexts/ThemeContext';
import axios from 'axios';
import { UserContext } from '../contexts/userContext';
import { useNavigate } from 'react-router-dom';
import CenteredLoader from "../components/Layouts/CenteredLoader";
import { showErrorNotification, showSuccessNotification } from '../components/Layouts/Notification';
import useUserAuthHook from '../hooks/useUserAuthHook';
import { apiUrl, fileUrl } from '../apiUrl';

const SingleCoursePage = () => {
  const { theme } = useTheme();
  const [isVideoFullScreen, setIsVideoFullScreen] = useState(false);
  const [progress, setProgress] = useState(70);
  const [courseData, setCourseData] = useState([]);
  const { userInfo, setUser } = useContext(UserContext);
  const [videoUrl, setVideoUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [getPageUrlId, setPageUrlId] = useState('');
  const [courseSubTopics, setCourseSubtopics] = useState('');
  const [activeIndex, setActiveIndex] = useState(-1);

  const navigate = useNavigate();
  const data = useUserAuthHook();

  useEffect(() => {
    if (data) {
      setUser(data);
    }
  }, [data, setUser]);

  const saveToShoppingCart = async (id, userId) => {
    const data = {
      userId: userId,
      courseId: id
    };
    await axios.post(`${apiUrl}/add-shopping-cart`, data)
      .then(res => {
        showSuccessNotification("Navigating to shopping cart page");
        navigate('/shopping-cart');
      }).catch(err => {
        console.log(err);
        showErrorNotification("Error: " + err.message);
      });
  };

  useEffect(() => {
    try {
      const urls = new URL(document.URL);
      const id = String(urls).split("/").pop();
      setPageUrlId(id);
    } catch (e) {
      showErrorNotification(e);
    }
  }, []);

  useEffect(() => {
    try {
      axios.get(`${apiUrl}/course?id=${getPageUrlId}`).then(response => {
        setCourseData(response.data);
        setVideoUrl(response.data.course_video);
        if (Object.values(userInfo).length > 0) {
          showSuccessNotification("Content finished loading");
          setLoading(false);
        }
      }).catch(error => {
        showErrorNotification(`Error: ${error}`);
      });
    } catch (err) {
      console.log(err);
    }
  }, [userInfo, getPageUrlId]);

  useEffect(() => {
    axios.get(`${apiUrl}/get-subtopics?courseId=${getPageUrlId}`)
    .then(res => {
      setCourseSubtopics(res.data);
    }).catch(err => {
      showErrorNotification(`Error Fetching Course  ${err}`);
    });
  }, [getPageUrlId]);

  const handleVideoClick = () => {
    setIsVideoFullScreen(true);
  };

  const handleExitFullScreen = () => {
    setIsVideoFullScreen(false);
  };

  return (
    loading ? (
      <div>
        <CenteredLoader />
      </div>
    ) : (
      <>
        <PageContainer theme={theme}>
          <Banner theme={theme}>
            <VideoSection>
              <VideoContainer onClick={handleVideoClick} isFullScreen={isVideoFullScreen}>
                {isVideoFullScreen && <ExitIcon onClick={handleExitFullScreen} />}
                <video controls width="100%">
                  <source src={`${fileUrl}${videoUrl}`} type="video/mp4" />
                </video>
              </VideoContainer>
              <PurchaseButton theme={theme} onClick={() => saveToShoppingCart(getPageUrlId, userInfo.id)}>Add to Cart</PurchaseButton>
            </VideoSection>
            <InfoSection>
              <Title>{courseData.course_title}</Title>
              <Info>
                <InfoItem theme={theme}><Label>Course Mode:</Label> {courseData.mode}</InfoItem>
                <InfoItem theme={theme}><Label>Course Schedule:</Label>Fri | Sat | Sunday</InfoItem>
                <InfoItem theme={theme}><Label>Language:</Label> English</InfoItem>
                {/* <InfoItem theme={theme}><Label>Progress:</Label> <ProgressIndicator progress={progress}>{progress}%</ProgressIndicator></InfoItem> */}
              </Info>
            </InfoSection>
          </Banner>

          <Content theme={theme}>
            <h2>Course Details</h2>
            <Accordion>
              <Accordion.Item>
              {courseSubTopics.map((subtopic, index) => (
  <Accordion.Item key={index} eventKey={index}>
    <Accordion.Header onClick={() => setActiveIndex(index === activeIndex ? -1 : index)}>
      {subtopic.topic_title}
    </Accordion.Header>
    <Accordion.Body show={activeIndex === index}>
      <div dangerouslySetInnerHTML={{ __html: subtopic.topic_description }} />
    </Accordion.Body>
  </Accordion.Item>
))}
              </Accordion.Item>
            </Accordion>
          </Content>

          <Requirements>
            <h2>Course Requirements</h2>
            <div dangerouslySetInnerHTML={{ __html: courseData.requirements }}></div>
          </Requirements>

          <Description>
            <h2>Course Description</h2>
            <div dangerouslySetInnerHTML={{ __html: courseData.description }}></div>
            <div dangerouslySetInnerHTML={{ __html: courseData.highlights }}></div>
          </Description>

          <FixedFooter theme={theme}>
            <FooterContent>
              <CourseDetails>
                <CourseTitle>{courseData.course_title}</CourseTitle>
              </CourseDetails>
              <PurchaseSection>
                <CoursePrice>#{Intl.NumberFormat('en-us').format(courseData.price)}</CoursePrice>
                <FooterPurchaseButton theme={theme} onClick={() => navigate(`/checkout/${courseData._id}`)}>Enroll Now</FooterPurchaseButton>
              </PurchaseSection>
            </FooterContent>
          </FixedFooter>
        </PageContainer>
      </>
    )
  );
};

const PageContainer = styled.div`
  padding: 20px;
  background-color: ${props => props.theme === 'light' ? '#fff' : '#333'};
  color: ${props => props.theme === 'light' ? '#000' : '#fff'};
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Banner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;

  @media(min-width: 768px) {
    flex-direction: row;
  }
`;

const VideoSection = styled.div`
  flex: 1;
  padding: 10px;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

const InfoSection = styled.div`
  flex: 1;
  padding: 10px;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bolder;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 10px;
  }
`;

const Info = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const InfoItem = styled.div`
  background-color: ${props => props.theme === 'light' ? '#f0f0f0' : '#555'};
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

const Label = styled.span`
  font-weight: bold;
  margin-right: 5px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const ProgressIndicator = styled.div`
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  position: relative;

  &::after {
    content: "${props => props.progress}%";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-weight: bold;
  }

  &::before {
    content: '';
    display: block;
    height: 100%;
    width: ${props => props.progress}%;
    background-color: green;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;

  video {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  ${props => props.isFullScreen && `
    position: fixed;
    top: 10%;
    left: 10%;
    width: 80vw;
    height: 80vh;
    background: rgba(0, 0, 0, 0.9);
    z-index: 1000;

    video {
      width: 100%;
      height: 100%;
    }
  `}
`;

const ExitIcon = styled(FaTimes)`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  z-index: 1001;
`;

const PurchaseButton = styled.button`
  padding: 10px;
  font-size: 1.3rem;
  font-weight: bold;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: ${props => props.theme === 'light' ? '0 4px 6px rgba(0, 0, 0, 0.1)' : '0 4px 6px rgba(255, 255, 255, 0.1)'};
  width: 100%;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 8px;
  }
`;

const Content = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const EnrolledText = styled.div`
  margin-left: auto;
  font-size: 1.1rem;
  background-color: yellow;
  padding: 5px;
  border-radius: 5%;

  @media (max-width: 780px) {
    margin-left: 40%;
  }
`;

const Requirements = styled.div`
  margin-bottom: 20px;

  h2 {
    font-weight: bold;
  }

  ul {
    display: block;
    font-size: 22px;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
`;

const Description = styled.div`
  margin-bottom: 20px;

  h2 {
    font-weight: bold;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const FixedFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${props => props.theme === 'light' ? '#fff' : '#333'};
  box-shadow: ${props => props.theme === 'light' ? '0 -4px 6px rgba(0, 0, 0, 0.1)' : '0 -4px 6px rgba(255, 255, 255, 0.1)'};
  padding: 10px;
  z-index: 7000;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

const CourseDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const CourseTitle = styled.div`
  font-size: 1.1rem;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const StudentEnrolled = styled.div`
  font-size: 1rem;
`;

const PurchaseSection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 5px;
  }
`;

const CoursePrice = styled.div`
  font-size: 1.5rem;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const FooterPurchaseButton = styled.button`
  padding: 10px;
  font-size: 1.3rem;
  font-weight: bold;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: ${props => props.theme === 'light' ? '0 4px 6px rgba(0, 0, 0, 0.1)' : '0 4px 6px rgba(255, 255, 255, 0.1)'};
  min-width: 35%;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 8px;
    min-width: 50%;
  }
`;

export default SingleCoursePage;
