import React, { useContext, useState, useEffect} from 'react';
import styled from 'styled-components';
import { FaEye, FaEyeSlash, FaCheck} from 'react-icons/fa';
import { useTheme } from '../contexts/ThemeContext';
import { UserContext } from '../contexts/userContext';
import axios from 'axios';
import { showErrorNotification, showSuccessNotification } from '../components/Layouts/Notification';
import useUserAuthHook from '../hooks/useUserAuthHook';
import useAuthPageAccess from '../hooks/useAuthPageAccess';
import { useDispatch } from 'react-redux';
// url file
import { apiUrl } from '../apiUrl';

axios.defaults.withCredentials = true;

const LoginPage = () => {
  const [referrerOrigin, setReferrerOrigin] = useState();
  const [currentOrigin, setCurrentOrigin] = useState();
  const {userInfo, setUser} = useContext(UserContext);
  const { theme } = useTheme();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const [sanitized, setSanitized] = useState({});
  const [userExist, setUserExist] = useState(true);
  const dispatch = useDispatch();

  const data = useUserAuthHook();
  useAuthPageAccess();
  useEffect(() => {
    if (data) {
      setUser(data);
    }
  }, [data, setUser]);
  
  useEffect(()=> {
    const refOrigin = window.localStorage.getItem('referrerPage');
    setReferrerOrigin(refOrigin || document.referrer);
    setCurrentOrigin(window.location.origin);
  }, [setCurrentOrigin, setReferrerOrigin]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // function to handle whem typing into the input box
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  // sanitized function
  const sanitizeInputs = (name, value) => {
    let error;
    let sanitized;

    switch (name) {
      case "email" :
        if (!value.includes('@')) {
          error = 'Invalid email address';
        } else {
          sanitized = value.trim();
          setInputs({...error, email : sanitized});
        }
        break;

        case "passwords" :
          if (value.length < 6) {
            error = 'Password must be at least 6 characters long';
            sanitized = value.trim();
          setInputs({...error, password : sanitized});

          }
        break;
        default :
        break;
    }
    

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name] : error
    }));

    setSanitized((prevSanitized) => ({
      ...prevSanitized,
      [name] : sanitized
    }));
 
  };

const handleBlur = (e) => {
const {name, value} = e.target;
sanitizeInputs(name, value);
}

const handleSubmit = (e) => {
e.preventDefault();
try {
  axios.post(`${apiUrl}/login`, {
    email: inputs.email,
    password: inputs.password
  })
  .then(response => {
    showSuccessNotification("Authentication complete: " + response.status);
    setUser(response.data);
    dispatch({type : 'SET_USER', payload : response.data});
    localStorage.setItem('user', JSON.stringify(response.data));
    if (referrerOrigin !== currentOrigin) {
      window.location.href = '/';
      } else {
      window.location.href = referrerOrigin;
      }
  })
  .catch(err => {
    showErrorNotification("Error: " + err.message);
    setErrors({...errors,  generalError: `error logging in ${err.message}`});
  });

} catch (err) {
setErrors({...errors, generalError : err});
}
};


  return (
    <Container theme={theme}>
      <Form theme={theme} onSubmit={handleSubmit} method="post">
        <Title>Login</Title>
        <InputContainer>
          <Input
            theme={theme}
            type="email"
            name="email"
            placeholder="Email"
            value={inputs.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        <ErrorContainer theme={theme}>
          {errors.email && <Error>{errors.email}</Error>}
          {sanitized.email && <FaCheck />}
          </ErrorContainer>
        </InputContainer>
        <InputContainer>
          <Input
            theme={theme}
            type={passwordVisible ? 'text' : 'password'}
            name="password"
            placeholder="Password"
            value={inputs.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ToggleIcon onClick={togglePasswordVisibility}>
            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
          </ToggleIcon>
          {sanitized.password && <FaCheck />}
          <ErrorContainer theme={theme}>
          {errors.password && <Error>{errors.password}</Error>}
          </ErrorContainer>
        </InputContainer>
        <Button theme={theme} >Button</Button>
        <ErrorContainer theme={theme}>
        {errors.generalError && <Error>{errors.generalError}</Error>}
        </ErrorContainer>
        <SocialButtons>
          <SocialButton theme={theme}>GitHub</SocialButton>
          <SocialButton theme={theme}>Google</SocialButton>
          <SocialButton theme={theme}>Facebook</SocialButton>
        </SocialButtons>
        <Text>Don't have an account? <Link href="/SignUp">Sign up</Link></Text>
      </Form>
    </Container>
  );
};

export default LoginPage;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${props => props.theme === 'light' ? '#f9f9f9' : '#333'};
  margin : 70px 0;
  min-width : 70%;
`;

const Form = styled.form`
  background-color: ${props => props.theme === 'light' ? '#fff' : '#444'};
  padding: 2rem;
  border-radius: 10px;
  box-shadow: ${props => props.theme === 'light' ? '0 4px 6px rgba(0, 0, 0, 0.1)' : '0 4px 6px rgba(255, 255, 255, 0.1)'};
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Title = styled.h2`
  margin-bottom: 1rem;
`;

const InputContainer = styled.div`
  position: relative;
  align-items: center;
  display : flex;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid ${props => props.theme === 'light' ? '#ddd' : '#666'};
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: ${props => props.theme === 'light' ? '#007bff' : '#007bff'};
  }
`;

const ToggleIcon = styled.div`
  position: absolute;
  right: 10px;
  cursor: pointer;
`;

const ErrorContainer = styled.div`
color : ${props => props.theme === 'light' ? '#333' : "#fff"}
`;
const Error = styled.span`
  color: red;
  font-size: 0.8rem;
`;

const Button = styled.button`
  padding: 0.75rem;
  background-color: ${props => props.theme === 'light' ? '#007bff' : '#0056b3'};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: ${props => props.theme === 'light' ? '0 4px 6px rgba(0, 0, 0, 0.1)' : '0 4px 6px rgba(255, 255, 255, 0.1)'};
  transition: background-color 0.3s;

  &:hover {
    background-color: ${props => props.theme === 'light' ? '#0056b3' : '#003f7f'};
  }
`;

const SocialButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const SocialButton = styled.button`
  padding: 0.75rem;
  background-color: ${props => props.theme === 'light' ? '#fff' : '#444'};
  color: ${props => props.theme === 'light' ? '#333' : '#fff'};
  border: 1px solid ${props => props.theme === 'light' ? '#ddd' : '#666'};
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: ${props => props.theme === 'light' ? '0 4px 6px rgba(0, 0, 0, 0.1)' : '0 4px 6px rgba(255, 255, 255, 0.1)'};
  transition: background-color 0.3s;

  &:hover {
    background-color: ${props => props.theme === 'light' ? '#f9f9f9' : '#555'};
  }
`;

const Text = styled.p`
  margin-top: 1rem;
  text-align: center;
`;

const Link = styled.a`
  color: ${props => props.theme === 'light' ? '#007bff' : '#007bff'};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
