import { Outlet } from "react-router-dom";
import Header from "./components/Layouts/Header";
import Footer from "./components/Layouts/Footer";
import styled from 'styled-components';
import { useTheme } from './contexts/ThemeContext';

export default function Layout() {
const {theme} = useTheme();
return (
    <BodyContainer theme={theme}>
        <Header />
        <Outlet />
        <Footer />
    </BodyContainer>
);
}

const BodyContainer = styled.div`
width : 100%;
background-color : ${(props) => props.theme === 'light' ? '#ffffff' : 'rgba(51,51,51,0.8)'};
margin : 0;
padding : 0;
`;