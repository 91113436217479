import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { useTheme } from '../contexts/ThemeContext';
import { UserContext } from '../contexts/userContext';
import useUserAuthHook from '../hooks/useUserAuthHook';
import { Countries } from '../services/Countries';
import { apiUrl, fileUrl } from '../apiUrl';

const CheckoutPage = () => {
  const { theme } = useTheme();
  const { userInfo, setUser } = useContext(UserContext);
  const { id } = useParams(); // Assume URL param is named 'id'
  const courseIds = id.split(',');
  const [courses, setCourses] = useState([]);
  const [originalTotal, setOriginalTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);

  const data = useUserAuthHook();
  setUser(data);

  const sortImageUrl = (listOfUrls) => {
    return listOfUrls.map((value) => {
      const slicedImage = value.course_image.split("\\");
      const extractedImage = slicedImage[slicedImage.length - 1];
      return { ...value, course_image: extractedImage };
    });
  };

  useEffect(() => {
    if (!userInfo) {
      window.location.href = "/login";
      return;
    }

    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${apiUrl}/get-all-courses`, {
          params: {
            ids: courseIds
          }
        });
        const updatedArray = sortImageUrl(response.data);
        const fetchedCourses = updatedArray;
        setCourses(fetchedCourses);

        let originalSum = 0;
        let discountSum = 0;

        fetchedCourses.forEach(course => {
          originalSum += parseFloat(course.price) * 1.8;
          discountSum += parseFloat(course.price);
        });

        setOriginalTotal(originalSum);
        setDiscountTotal(discountSum);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, []);

  const handlePayment = async () => {
    try {
      await axios.post(`${apiUrl}/pay`, {
        amount: discountTotal,
        courseDetails: courses,
        userData: userInfo
      }).then(response => {
        const authorizationUrl = response.data.authorizationUrl;
        window.location.href = authorizationUrl;
      }).catch(err => {
        alert("Error initiating payment");
      });

    } catch (error) {
      console.error('Error making payment:', error);
    }
  };

  const chatOnWhatsapp = () => {
    window.open('https://wa.me/+2348179776605?text=I want to pay, but i need more info', '_blank');
  };


  return (
    <CheckoutContainer theme={theme}>
      <Column>
        <BoldText>Checkout</BoldText>
        <Label>Country</Label>
        <Select theme={theme}>
          <option value='Nigeria' default>Nigeria</option>
          {
            Countries.map(item => (
              <option key={item.name} value={item.name}>{item.name}</option>
            ))
          }
        </Select>
        <SectionMargin>
          <BoldText>Other Details</BoldText>
          {courses.map(course => (
            <DetailsRow key={course._id} theme={theme}>
              <Image src={`${fileUrl}${course.course_image}`} alt="Course Thumbnail" />
              <TextColumn>
                <CourseTitle>{course.course_title}</CourseTitle>
              </TextColumn>
              <PriceColumn>
              
                <OriginalPrice>#{Intl.NumberFormat('en-us').format(course.price * 1.80)}</OriginalPrice>
                <DiscountPrice>#{Intl.NumberFormat('en-us').format(course.price)}</DiscountPrice>
              </PriceColumn>
            </DetailsRow>
          ))}
        </SectionMargin>
      </Column>
      <ColumnLeft>
        <BoldText>Order Summary</BoldText>
        <SummaryRow>
          <SummaryLabel>Original Price:</SummaryLabel>
          <SummaryValue>#{Intl.NumberFormat('en-us').format(originalTotal.toFixed(2))}</SummaryValue>
        </SummaryRow>
        <SummaryRow>
          <SummaryLabel>Discount Price:</SummaryLabel>
          <SummaryValue>#{Intl.NumberFormat('en-us').format(discountTotal.toFixed(2))}</SummaryValue>
        </SummaryRow>
        <SummaryRow>
          <SummaryLabel>Total Price:</SummaryLabel>
          <SummaryTotal>#{Intl.NumberFormat('en-us').format(discountTotal.toFixed(2))}</SummaryTotal>
        </SummaryRow>
        <ButtonRow>
          <ActionButton theme={theme} onClick={handlePayment}>Complete Checkout</ActionButton>
          <ActionButton theme={theme} onClick={chatOnWhatsapp}>Chat Admin on WhatsApp</ActionButton>
        </ButtonRow>
      </ColumnLeft>
    </CheckoutContainer>
  );
};

const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  color: ${props => props.theme === 'light' ? '#333' : '#fff'};
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Column = styled.div`
  flex: 2;
  padding: 1rem;

  @media (max-width: 768px) {
  flex : 1;
  }
`;

const ColumnLeft = styled.div`
  flex: 1;
  padding: 1rem;
`;

const BoldText = styled.h2`
  font-weight: bold;
  margin-bottom: 1rem;
`;

const Label = styled.label`
  margin-bottom: 0.5rem;
  display: block;
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid ${props => props.theme === 'light' ? '#ddd' : '#666'};
  border-radius: 5px;
  background-color: ${props => props.theme === 'light' ? '#fff' : '#555'};
  color: ${props => props.theme === 'light' ? '#333' : '#fff'};
  margin-bottom: 1rem;
`;

const SectionMargin = styled.div`
  margin-top: 1rem;
`;

const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

`;

const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 1rem;

  @media (max-width: 768px) {
  }
`;

const TextColumn = styled.div`
  flex: 1;
`;

const CourseTitle = styled.p`
  font-weight: bold;
  @media (max-width: 768px) {
  font-size : 0.6rem;
  }
  
`;

const PriceColumn = styled.div`
  text-align: right;
`;

const OriginalPrice = styled.p`
  text-decoration: line-through;
`;

const DiscountPrice = styled.p`
  font-weight: bold;
`;

const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const SummaryLabel = styled.span``;

const SummaryValue = styled.span``;

const SummaryTotal = styled.span`
  font-weight: bold;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const ActionButton = styled.a`
  padding: 0.75rem;
  text-align: center;
  background-color: ${props => props.theme === 'light' ? '#007bff' : '#1a73e8'};
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  &:hover {
    background-color: ${props => props.theme === 'light' ? '#0056b3' : '#135ab6'};
  }
`;

export default CheckoutPage;
