import React, { useContext, useState, useEffect} from 'react';
import styled from 'styled-components';
import { FaEye, FaEyeSlash, FaCheck} from 'react-icons/fa';
import { useTheme } from '../contexts/ThemeContext';
import { Countries } from '../services/Countries';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/userContext';
import { showErrorNotification, showSuccessNotification } from '../components/Layouts/Notification';
import useSetReferrerPage from '../hooks/useSetReferrerPage'
import useUserAuthHook from '../hooks/useUserAuthHook';
import useAuthPageAccess from '../hooks/useAuthPageAccess';
import { apiUrl } from '../apiUrl';

const SignUpPage = () => { 
  const [referrerOrigin, setReferrerOrigin] = useState();
  const [currentOrigin, setCurrentOrigin] = useState(window.location.origin);
  const { theme } = useTheme();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    phoneNumber: '',
    country: 'Nigeria',
    address: '',
    gender : 'none',
    image: {},
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [sanitized, setSanitized] = useState({});
  const [imagePreview, setImagePreview] = useState(null);
  const [emailCheckResponse, setEmailCheck] = useState(null);
  const navigate = useNavigate();
  // check if user is logged in then redirect
  useAuthPageAccess();
  // add the referring page to the browser local storage
  useSetReferrerPage();  
  // useEffect to set the referrer origin and the current origin
  useEffect(() => {
    try {
    const refOrigin = window.localStorage.getItem('referrerPage');
    setReferrerOrigin(refOrigin || document.referrer);
    setCurrentOrigin(window.location.origin);
    } catch (e) {
      showErrorNotification(e);
    }
  }, []);
  

  const getUserInfo = () => ({
    userAgent: navigator.userAgent,
    language: navigator.language,
    geolocation: new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position.coords),
        () => resolve(null)
      );
    }),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    showSuccessNotification(file.size, file.type);
    if (file) {
      if((file.size / 1024) > 15704) {
        setErrors({...errors, image:"Wrong Image Size, image should not be more than 10mb"})
      } else if(file.type === "image/.png" || file.type === "image/.jpeg" || file.type === "image/.jpg" || file.type === "image/.webp"){
        setErrors({...errors, image:"Image can only be jpeg, png or webp"})
      } else {
      setFormData({ ...formData, image: file });
      setSanitized({ ...sanitized, image: file.type });
      setImagePreview(URL.createObjectURL(file));
      }
      
    } else {
      showErrorNotification("No file detected");
    }
  };

  const validateInput = (name, value) => {
    let error = '';
    let sanitizedValue = '';

    switch (name) {
      case 'name':
      case 'country':
      case 'gender' :
        if (!value || value.length < 3) {
          error = `${name.charAt(0).toUpperCase() + name.slice(1)} must be at least 3 characters`;
        } else if (/[^a-zA-Z0-9\- ]/.test(value)) {
          error = `${name.charAt(0).toUpperCase() + name.slice(1)} contains invalid characters`;
        } else {
          sanitizedValue = value;
        }
        break;
      case 'email':
        if (!/\S+@\S+\.\S+/.test(value)) {
          error = 'Email is invalid';
        } else {
          sanitizedValue = value;
        }
        break;
      case 'password':
        if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/.test(value)) {
          error = 'Password must contain lowercase, uppercase and a number';
        } else {
          sanitizedValue = value;
        }
        break;
      case 'phoneNumber':
        if (!/^\d+$/.test(value)) {
          error = 'Phone number is invalid';
        } else {
          sanitizedValue = value;
        }
        break;
      case 'address':
        if (!value || value.length < 3) {
          error = `${name.charAt(0).toUpperCase() + name.slice(1)} must be at least 3 characters`;
        } else if (/[^a-zA-Z0-9\-@,/ ]/.test(value)) {
          error = `${name.charAt(0).toUpperCase() + name.slice(1)} contains invalid characters`;
        } else {
          sanitizedValue = value;
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));

    setSanitized((prevSanitized) => ({
      ...prevSanitized,
      [name]: sanitizedValue,
    }));
  };

  const handleBlur = async (e) => {
    const { name, value } = e.target;
    validateInput(name, value);
    }

  const handleSubmit = (e) => {
    e.preventDefault();
    // showSuccessNotification("trying to submit");
    // Validate all inputs
    Object.keys(formData).forEach((key) => validateInput(key, formData[key]));
    // showSuccessNotification(`adress : ${formData.address}`);
    // showSuccessNotification(`email : ${formData.email}`);
    // showSuccessNotification(`password : ${formData.password}`);
    // showSuccessNotification(`Phone number : ${formData.phoneNumber}`);
    // showSuccessNotification(`name : ${formData.name}`);
    // const hasErrors = Object.values(errors).some((error) => error !== '');
    const allFieldsFilled = Object.values(sanitized).every((value) => value !== '');
    if (allFieldsFilled) {
      try {
        const userInfo = getUserInfo();
        const formDataToSubmit = new FormData();
        Object.keys(formData).forEach((key) => {
          formDataToSubmit.append(key, formData[key]);
          // showSuccessNotification(`${key} : ${formData[key]}`);
        });
        formDataToSubmit.append('userAgent', userInfo.userAgent);
        formDataToSubmit.append('language', userInfo.language);
        if (userInfo.geolocation) {
          formDataToSubmit.append('geolocation', JSON.stringify(userInfo.geolocation));
        }

  axios.get(`${apiUrl}/users?email=${formData.email}`).then((res) =>{
    setEmailCheck(res);
    showSuccessNotification(res.status);
    axios.post(`${apiUrl}/usersingle`, formDataToSubmit, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response) => {
      showSuccessNotification(`sucessfuly signup ${response.status}`);
      navigate("/login");
    }).catch((error) => {
      showErrorNotification(`Error message: ${error}`);
      showErrorNotification(`Error data: ${error.response.data.message}`);
    });
      }).catch((error) => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: 'User with this email already exists',
        }));
        showErrorNotification(error.data.message);
      });
      } catch (error) {
        showErrorNotification(`Error during signup: ${error}`);
      }
    } else {
      showErrorNotification(`Form validation failed: : ${errors.email}`);
      showErrorNotification(`Form validation failed: : ${errors.image}`);
    }
  };

return (
    <AuthContainer theme={theme}>
      <AuthForm theme={theme}  onSubmit={handleSubmit} method="post" enctype="multipart/form-data">
        <h2>Sign Up</h2>
        <FormGroup>
          <Label>Name</Label>
          <Input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            onBlur={handleBlur}
            theme={theme}
          />
          {sanitized.name && <FaCheck />}
          {errors.name && <Error>{errors.name}</Error>}
        </FormGroup>
        <FormGroup>
          <Label>Email</Label>
          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            onBlur={handleBlur}
            theme={theme}
          />
          {sanitized.email && <FaCheck />}
          {errors.email && <Error>{errors.email}</Error>}
        </FormGroup>
        <FormGroup>
          <Label>Password</Label>
          <PasswordWrapper theme={theme}>
            <Input
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              onBlur={handleBlur}
              theme={theme}
            />
            <PasswordIcon onClick={() => setShowPassword(!showPassword)} theme={theme}>
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </PasswordIcon>
          </PasswordWrapper>
          {sanitized.password && <FaCheck />}
          {errors.password && <Error>{errors.password}</Error>}
        </FormGroup>
        <FormGroup>
          <Label>Phone Number</Label>
          <Input
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            onBlur={handleBlur}
            theme={theme}
          />
          {sanitized.phoneNumber && <FaCheck />}
          {errors.phoneNumber && <Error>{errors.phoneNumber}</Error>}
        </FormGroup>
        <FormGroup>
          <Label>Country</Label>
          <Select
            name="country"
            value={formData.country}
            onChange={handleInputChange}
            onBlur={handleBlur}
            theme={theme}
          >
            {Countries.map((data) => (
              <option value={data.name} key={data.code}>
                {data.name}
              </option>
            ))}
          </Select>
          {sanitized.country && <FaCheck />}
          {errors.country && <Error>{errors.country}</Error>}
        </FormGroup>
        <FormGroup>
          <Label>Gender</Label>
          <Select
            name="gender"
            value={formData.gender}
            onChange={handleInputChange}
            onBlur={handleBlur}
            theme={theme}
            required
          >
            <option value='none' key='none' default>None</option>
            <option value='male' key='male'>Male</option>
            <option value='female' key='female'>Female</option>
          </Select>
          {sanitized.country && <FaCheck />}
          {errors.country && <Error>{errors.country}</Error>}
        </FormGroup>
        <FormGroup>
          <Label>Address</Label>
          <Input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            onBlur={handleBlur}
            theme={theme}
          />
          {sanitized.address && <FaCheck />}
          {errors.address && <Error>{errors.address}</Error>}
        </FormGroup>
        <FormGroup>
          <Label>Profile Image</Label>
          <Input
            type="file"
            name="image"
            onChange={handleImageChange}
            theme={theme}
          />
          {errors.image && <Error>{errors.image}</Error>}
          {imagePreview && (
            <ImagePreview>
              <img src={imagePreview} alt="Profile Preview" />
            </ImagePreview>
          )}
        </FormGroup>
        <Input type='submit' theme={theme} />

        <Text>
          Already have an account? <Link href="/Login">Login</Link>
        </Text>
        <SocialButtons>
          <SocialButton theme={theme}>GitHub</SocialButton>
          <SocialButton theme={theme}>Google</SocialButton>
          <SocialButton theme={theme}>Facebook</SocialButton>
        </SocialButtons>
      </AuthForm>

      
    </AuthContainer>
  );
};

const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: ${(props) => (props.theme === 'light' ? '#f9f9f9' : '#333')};
  color: ${(props) => (props.theme === 'light' ? '#333' : '#f9f9f9')};
`;

const AuthForm = styled.form`
  width: 100%;
  max-width: 400px;
  background-color: ${(props) => (props.theme === 'light' ? '#fff' : '#444')};
  padding: 2rem;
  border-radius: 10px;
  box-shadow: ${(props) => (props.theme === 'light' ? '0 4px 6px rgba(0, 0, 0, 0.1)' : '0 4px 6px rgba(255, 255, 255, 0.1)')};
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
  position: relative;
`;

const Label = styled.p`
  display: block;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid ${(props) => (props.theme === 'light' ? '#ddd' : '#666')};
  border-radius: 5px;
  background-color: ${(props) => (props.theme === 'light' ? '#fff' : '#555')};
  color: ${(props) => (props.theme === 'light' ? '#333' : '#fff')};
  box-shadow: ${(props) => (props.theme === 'light' ? '0 2px 4px rgba(0, 0, 0, 0.1)' : '0 2px 4px rgba(255, 255, 255, 0.1)')};
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: ${(props) => (props.theme === 'light' ? '#007bff' : '#007bff')};
  }
`;

const PasswordWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PasswordIcon = styled.div`
  margin-left: -2rem;
  cursor: pointer;
  color: ${(props) => (props.theme === 'light' ? '#333' : '#fff')};
`;

const Error = styled.div`
  color: red;
  margin-top: 0.5rem;
  font-size: 0.875rem;
`;

const AuthButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  background-color: ${(props) => (props.theme === 'light' ? '#007bff' : '#1a73e8')};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: ${(props) => (props.theme === 'light' ? '0 4px 6px rgba(0, 0, 0, 0.1)' : '0 4px 6px rgba(255, 255, 255, 0.1)')};
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => (props.theme === 'light' ? '#0056b3' : '#135ab6')};
  }
`;

const SocialButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const SocialButton = styled.button`
  padding: 0.75rem;
  background-color: ${(props) => (props.theme === 'light' ? '#fff' : '#444')};
  color: ${(props) => (props.theme === 'light' ? '#333' : '#fff')};
  border: 1px solid ${(props) => (props.theme === 'light' ? '#ddd' : '#666')};
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: ${(props) => (props.theme === 'light' ? '0 4px 6px rgba(0, 0, 0, 0.1)' : '0 4px 6px rgba(255, 255, 255, 0.1)')};
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => (props.theme === 'light' ? '#f9f9f9' : '#555')};
  }
`;

const Text = styled.p`
  margin-top: 1rem;
  text-align: center;
`;

const Link = styled.a`
  color: ${(props) => (props.theme === 'light' ? '#007bff' : '#007bff')};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid ${(props) => (props.theme === 'light' ? '#ddd' : '#666')};
  border-radius: 5px;
  background-color: ${(props) => (props.theme === 'light' ? '#fff' : '#555')};
  color: ${(props) => (props.theme === 'light' ? '#333' : '#fff')};
  box-shadow: ${(props) => (props.theme === 'light' ? '0 2px 4px rgba(0, 0, 0, 0.1)' : '0 2px 4px rgba(255, 255, 255, 0.1)')};
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: ${(props) => (props.theme === 'light' ? '#007bff' : '#007bff')};
  }
`;

const ImagePreview = styled.div`
  margin-top: 1rem;
  img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-height: 200px;
    object-fit: contain;
    object-position: center center;
  }
`;
export { SignUpPage };
