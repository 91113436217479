import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useTheme } from '../contexts/ThemeContext';
import { UserContext } from '../contexts/userContext';
import useUserAuthHook from '../hooks/useUserAuthHook';
import { FaWhatsapp, FaTelegram, FaFacebook, FaTwitter, FaLinkedin, FaYoutube } from 'react-icons/fa';
import { apiUrl, fileUrl } from '../apiUrl';
// images
const websiteImage = `${fileUrl}codeIgnitelogo%403x.png`;
axios.defaults.withCredentials = true;

const facebookIcon = 'https://cdn-icons-png.flaticon.com/512/124/124010.png'; 
const twitterIcon = 'https://cdn-icons-png.flaticon.com/512/124/124021.png';
const telegramIcon = 'https://cdn-icons-png.flaticon.com/512/2111/2111646.png';
const linkedinIcon = 'https://cdn-icons-png.flaticon.com/512/174/174857.png';

const PaymentCallback = () => {
  const { theme } = useTheme();
  const { userInfo, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [paymentDetails, setPaymentDetails] = useState(null);
  const data = useUserAuthHook();

  useEffect(() => {
    if (data) {
      setUser(data);
    }
  }, [data, setUser]);

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const query = new URLSearchParams(window.location.search);
        const reference = query.get('reference');
        const response = await axios.post(`${apiUrl}/callback`, {
          reference,
          userInfo: userInfo,
        });
        setPaymentDetails(response.data);
      } catch (error) {
        console.error('Error fetching payment details:', error);
      }
    };

    fetchPaymentDetails();
  }, [userInfo]);

  const downloadPDF = () => {
    const doc = new jsPDF();
    
    // Add letterhead
    doc.addImage(websiteImage, 'PNG', 10, 10, 30, 30); // Add company logo
    doc.setFontSize(16);
    doc.text('codeIgnite', 50, 20);
    doc.setFontSize(12);
    doc.text('Email: admin@codeignite.net', 50, 30);
    doc.text('Follow us:', 50, 40);
    
    // Add social media icons
    const iconSize = 10;
    doc.addImage(facebookIcon, 'PNG', 80, 35, iconSize, iconSize);
    doc.addImage(twitterIcon, 'PNG', 95, 35, iconSize, iconSize);
    doc.addImage(telegramIcon, 'PNG', 110, 35, iconSize, iconSize);
    doc.addImage(linkedinIcon, 'PNG', 125, 35, iconSize, iconSize);
    
    // Add the rest of the content
    doc.text('Payment Details', 20, 60);
    
    if (paymentDetails) {
      const { metadata, amount, message, tx_ref, updated_at, card, currency } = paymentDetails;
      const courseData = metadata.map(course => [
        course.course_title,
        course.price,
        course.mode
      ]);
    
      doc.autoTable({
        head: [['Course Title', 'Price', 'Mode']],
        body: courseData,
        startY: 70,
      });
    
      const finalY = doc.autoTable.previous.finalY + 10;
      doc.text(`Total Amount Paid: #${amount}`, 20, finalY);
      doc.text(`Email: ${userInfo.email}`, 20, finalY + 10);
      doc.text(`Transaction Reference: ${tx_ref}`, 20, finalY + 20);
      doc.text(`Paid at: ${updated_at}`, 20, finalY + 30);
      doc.text(`Card: ${card}`, 20, finalY + 40);
      doc.text(`Currency: ${currency}`, 20, finalY + 50);
      doc.text(`Message: ${message}`, 20, finalY + 60);
    
      doc.save('payment-details.pdf');
    }
  };
  
  const chatOnWhatsapp = () => {
    window.open('https://wa.me/+2348179776605?text=I%20have%20paid,%20I%20want%20to%20request%20lesson%20time%20and%20date', '_blank');
  };

  const chatOnTelegram = () => {
    window.open('https://t.me/your_telegram_id?text=I%20have%20paid,%20I%20want%20to%20request%20lesson%20time%20and%20date', '_blank');
  };

  if (!paymentDetails) {
    return <div>Loading...</div>;
  }

  const { metadata, amount, message, tx_ref, updated_at, card } = paymentDetails;

  return (
    <Container theme={theme}>
      <Letterhead>
        <Logo src={websiteImage} alt="Company Logo" />
        <CompanyDetails>
          <CompanyName>codeIgnite</CompanyName>
          <CompanyContact>Tel: +2348143164036</CompanyContact>
          <SocialLinks>
            <SocialIcon href="https://www.facebook.com/codeignitehandle" target="_blank"><FaFacebook /></SocialIcon>
            <SocialIcon href="https://x.com/banabaz" target="_blank"><FaTwitter /></SocialIcon>
            <SocialIcon href="https://www.linkedin.com/company/101636797" target="_blank"><FaLinkedin /></SocialIcon>
            <SocialIcon href="https://www.youtube.com/@codeignitehandle" target="_blank"><FaYoutube /></SocialIcon>
          </SocialLinks>
        </CompanyDetails>
      </Letterhead>
      <Title>Payment Details</Title>
      <Details>
        <DetailItem><strong>Email:</strong> {userInfo.email}</DetailItem>
        <DetailItem><strong>Total Amount Paid:</strong> #{amount}</DetailItem>
        <DetailItem><strong>Transaction Reference:</strong> {tx_ref}</DetailItem>
        <DetailItem><strong>Status:</strong> Success</DetailItem>
        <DetailItem><strong>Paid at:</strong> {updated_at}</DetailItem>
        <DetailItem><strong>Card:</strong> {card}</DetailItem>
        <DetailItem><strong>Message:</strong> {message}</DetailItem>
      </Details>
      <CourseList>
        {metadata.map((course, index) => (
          <CourseItem key={index}>
            <strong>Title:</strong> {course.course_title} <strong>Price:</strong> #{course.price * 0.5}
          </CourseItem>
        ))}
      </CourseList>
      <ChatSection>
        <ChatText>Add chat with us to get available lesson period</ChatText>
        <ButtonRow>
          <ChatButton theme={theme} onClick={chatOnWhatsapp}><FaWhatsapp /> Chat on WhatsApp</ChatButton>
          <ChatButton theme={theme} onClick={chatOnTelegram}><FaTelegram /> Chat on Telegram</ChatButton>
        </ButtonRow>
      </ChatSection>
      <GoToDashboardSection>
        <ChatText>Go to dashboard to select lesson period and track progress</ChatText>
        <ActionButton theme={theme} onClick={downloadPDF}>Download as PDF</ActionButton>
        <ActionButton theme={theme} onClick={() => navigate(`/user-dashboard/${userInfo.id}`)}>Go to Dashboard</ActionButton>
      </GoToDashboardSection>
    </Container>
  );
};

const Container = styled.div`
  padding: 2rem;
  background-color: ${props => props.theme === 'light' ? '#f9f9f9' : '#333'};
  color: ${props => props.theme === 'light' ? '#000' : '#fff'};
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  max-width: 800px;
  margin: 2rem auto;
`;

const Letterhead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme === 'light' ? '#e3f2fd' : '#1a73e8'};
  color: ${props => props.theme === 'light' ? '#000' : '#fff'};
  padding: 1rem;
  border-radius: 10px;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
`;

const CompanyDetails = styled.div`
  text-align: right;
`;

const CompanyName = styled.h2`
  margin: 0;
  font-size: 1.5rem;
`;

const CompanyContact = styled.p`
  margin: 0.5rem 0;
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const SocialIcon = styled.a`
  color: ${props => props.theme === 'light' ? '#007bff' : '#fff'};
  font-size: 1.5rem;
  &:hover {
    color: ${props => props.theme === 'light' ? '#0056b3' : '#d1d1d1'};
  }
`;

const Title = styled.h1`
  margin-bottom: 1rem;
  text-align: center;
`;

const Details = styled.div`
  margin-bottom: 1rem;
`;

const DetailItem = styled.div`
  margin-bottom: 0.5rem;
`;

const CourseList = styled.div`
  margin-bottom: 1rem;
`;

const CourseItem = styled.div`
  margin-bottom: 0.5rem;
`;

const ChatSection = styled.div`
  margin-bottom: 1rem;
  text-align: center;
`;

const ChatText = styled.p`
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

const ChatButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  background-color: ${props => props.theme === 'light' ? '#25D366' : '#128C7E'};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  &:hover {
    background-color: ${props => props.theme === 'light' ? '#128C7E' : '#075E54'};
  }
  svg {
    margin-right: 0.5rem;
  }
`;

const GoToDashboardSection = styled.div`
  text-align: center;
  margin-top: 1rem;
`;

const ActionButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: ${props => props.theme === 'light' ? '#007bff' : '#1a73e8'};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin: 0.5rem 0;
  &:hover {
    background-color: ${props => props.theme === 'light' ? '#0056b3' : '#135ab6'};
  }
`;

export default PaymentCallback;
