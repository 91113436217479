import React from 'react';

//import from react-router-dom
import {Route, Routes} from 'react-router-dom';

// importing context hook and other functions
import { ThemeProvider } from './contexts/ThemeContext';
import { UserProvider } from './contexts/userContext';
import Layout from './Layout';
import 'bootstrap/dist/css/bootstrap.min.css';


// importing pages
import HomePage from './pages/HomePage';
import CoursesPage from './pages/CoursesPage';
import SignUpPageAdmin from './pages/SignUpPageAdmin.js';
import ServicesPage from './pages/ServicesPage';
import ContactPage from './pages/ContactPage';
import BlogPage from './pages/BlogPage';
import SingleCoursePage from './pages/SingleCoursePage';
import LoginPage from './pages/LoginPage';
import { SignUpPage } from './pages/SignUpPage';
import CheckoutPage from './pages/checkoutPage';
import ShoppingCartPage from './pages/ShoppingCartPage';
import DashboardPage from './pages/UserDashboard';
import PaymentCallback from './pages/PaymentCallback';
import ReferralDashboard from './pages/ReferralDashboard';
import { ReferralSignup } from './pages/ReferralSignup';
import InstructorDashboard from './pages/InstructorDashboard';
import CourseCreationPage from './pages/CourseRegistration';
import SubtopicManagementPage from './pages/SubTopicManagement';
import ContentUploadPage from './pages/ContentUploadPage';
import CodingRoboticsPage from './pages/CodingAndRoboticForSchool'
import { UserContext } from './contexts/userContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {Provider} from 'react-redux'
import store from './store';


const App = () => {
const Navigate = useNavigate();
const user = useContext(UserContext);

  return (
    <Provider store={store}>
    <UserProvider> 
<ThemeProvider>
        <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="/courses" element={<CoursesPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/callback" element={<PaymentCallback />} />
          <Route path="/single-course/:id/:ref?" element={<SingleCoursePage />} />
          <Route path="/login" element={user ? <Navigate to="/" /> : <LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/coding-robotic-page" element={<CodingRoboticsPage />} />
          <Route path="/admin" element={<SignUpPageAdmin />} />
          <Route path="/checkout/:id" element={<CheckoutPage />} />
          <Route path="/shopping-cart" element={<ShoppingCartPage />} />
          <Route path="/user-dashboard" element={<DashboardPage />} />
          <Route path="/referral-dashboard" element={<ReferralDashboard />} />
          <Route path="/referral-signup" element={<ReferralSignup />} />
          <Route path="/instructor-dashboard" element={<InstructorDashboard />} />
          {/* <Route path="/courseRegistration" element={<CourseCreationPage />} /> */}
          <Route>
          <Route path="/course-creation" element={<CourseCreationPage prevPage="/instructor-dashboard" nextPage="/subtopic-management" />} />
          <Route path="/subtopic-management/:courseid" element={<SubtopicManagementPage prevPage="/course-creation" nextPage="/content-upload" />} />
          <Route path="/content-upload" element={<ContentUploadPage prevPage="/subtopic-management" nextPage="/instructor-dashboard" />} />
          <Route path="/instructor-dashboard" element={<InstructorDashboard />} />
          <Route path="/" element={<InstructorDashboard />} />
        </Route>
          </Route>
        </Routes>
        </ThemeProvider>
        </UserProvider>
        </Provider>
  );
};

export default App;
