import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import styled from 'styled-components';

const Navigation = ({ prevPage, nextPage }) => {
  const history = useNavigate();

  const handleNavigation = (path) => {
    if (path) {
      history(path);
    }
  };

  return (
    <NavContainer>
      <NavButton onClick={() => handleNavigation(prevPage)}>Previous</NavButton>
      <NavButton onClick={() => handleNavigation('/dashboard')}>
        <FaHome /> Dashboard
      </NavButton>
      <NavButton onClick={() => handleNavigation(nextPage)}>Next</NavButton>
    </NavContainer>
  );
};

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: ${props => (props.theme === 'light' ? '#fff' : '#2c2c2c')};
  border-top: 1px solid ${props => (props.theme === 'light' ? '#ccc' : '#444')};
`;

const NavButton = styled.button`
  padding: 10px 20px;
  background-color: ${props => (props.theme === 'light' ? '#007bff' : '#0069d9')};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  &:hover {
    background-color: ${props => (props.theme === 'light' ? '#0056b3' : '#0056b3')};
  }
`;

export default Navigation;
