import React from "react";
export default function BlogPage() {
    return (
    <div>
    <h1>This is Blog Page</h1>
    </div>
    );
}



