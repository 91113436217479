import React, { useState } from 'react';
import styled from 'styled-components';
import { FaEye, FaEyeSlash, FaCheck, FaTimes } from 'react-icons/fa';
import { useTheme } from '../contexts/ThemeContext';
import {Countries} from '../services/Countries';
import {BanksInNigeria} from '../services/BankInNigeria';

const ReferralSignup = () => {
  const { theme } = useTheme();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    phoneNumber: '',
    country: '',
    accountName : '',
    accountNumber : '',
    bankName : ''

  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [sanitized, setSanitized] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateInput = (name, value) => {
    let error = '';
    let sanitizedValue = '';

    switch (name) {
      case 'name':
        if (!value) {
          error = 'Name is required';
        } else {
          sanitizedValue = value;
        }
        break;

      case 'accountName':
        if (!value) {
          error = 'account name is required';
        } else {
          sanitizedValue = value;
        }
        break;

      case 'bankName':
        if (!value) {
          error = 'account name is required';
        } else {
          sanitizedValue = value;
        }
        break;

      case 'email':
        if (!/\S+@\S+\.\S+/.test(value)) {
          error = 'Email is invalid';
        } else {
          sanitizedValue = value;
        }
        break;

      case 'password':
        if (value.length < 6) {
          error = 'Password must be at least 6 characters';
        } else {
          sanitizedValue = value;
        }
        break;

      case 'phoneNumber':
        if (!/^\d+$/.test(value)) {
          error = 'Phone number is invalid';
        } else {
          sanitizedValue = value;
        }
        break;

        case 'accountNumber':
            if (!/^\d+$/.test(value)) {
                error = 'Phone number is invalid';
              } else {
                sanitizedValue = value;
              }
        break;

      case 'country':
        if (!value) {
          error = 'Country is required';
        } else {
          sanitizedValue = value;
        }
        break;

      default:
        break;
    }

    setErrors({
      ...errors,
      [name]: error
    });

    setSanitized({
      ...sanitized,
      [name]: sanitizedValue
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateInput(name, value);
  };

  return (
    <AuthContainer theme={theme}>
      <AuthForm theme={theme}>
        <h2>Sign Up</h2>
        <FormGroup>
          <Label>Name</Label>
          <Input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            onBlur={handleBlur}
            theme={theme}
          />
          {sanitized.name && <FaCheck />}
          {errors.name && <Error>{errors.name}</Error>}
        </FormGroup>

        <FormGroup>
          <Label>Email</Label>
          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            onBlur={handleBlur}
            theme={theme}
          />
          {sanitized.email && <FaCheck />}
          {errors.email && <Error>{errors.email}</Error>}
        </FormGroup>
        <FormGroup>
          <Label>Password</Label>
          <PasswordWrapper theme={theme}>
            <Input
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              onBlur={handleBlur}
              theme={theme}
            />
            <PasswordIcon
              onClick={() => setShowPassword(!showPassword)}
              theme={theme}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </PasswordIcon>
          </PasswordWrapper>
          {sanitized.password && <FaCheck />}
          {errors.password && <Error>{errors.password}</Error>}
        </FormGroup>

        <FormGroup>
          <Label>Phone Number</Label>
          <Input
            type="number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            onBlur={handleBlur}
            theme={theme}
          />
          {sanitized.phoneNumber && <FaCheck />}
          {errors.phoneNumber && <Error>{errors.phoneNumber}</Error>}
        </FormGroup>

        <FormGroup>
          <Label>Account Name</Label>
          <Input
            type="text"
            name="accountName"
            value={formData.accountName}
            onChange={handleInputChange}
            onBlur={handleBlur}
            theme={theme}
          />
          {sanitized.accountName && <FaCheck />}
          {errors.accountName && <Error>{errors.accountName}</Error>}
        </FormGroup>

        <FormGroup>
          <Label>Bank Name</Label>
          <Select
            name="bankName"
            value={formData.bankName}
            onChange={handleInputChange}
            onBlur={handleBlur}
            theme={theme}
          >
            {BanksInNigeria.map(data => (
                <option value={data} key={data}>{data}</option>
            ))}
            
          </Select>
          {sanitized.bankName && <FaCheck />}
          {errors.bankName && <Error>{errors.bankName}</Error>}
        </FormGroup>

        <FormGroup>
          <Label>Account Number</Label>
          <Input
            type="text"
            name="accountNumber"
            value={formData.accountNumber}
            onChange={handleInputChange}
            onBlur={handleBlur}
            theme={theme}
          />
          {sanitized.accountNumber && <FaCheck />}
          {errors.accountNumber && <Error>{errors.accountNumber}</Error>}
        </FormGroup>

        <FormGroup>
          <Label>Country</Label>
          <Select
            name="country"
            value={formData.country}
            onChange={handleInputChange}
            onBlur={handleBlur}
            theme={theme}
          >
            {Countries.map(data => (
                <option value={data.name} key={data.code}>{data.name}</option>
            ))}
            
          </Select>
          {sanitized.country && <FaCheck />}
          {errors.country && <Error>{errors.country}</Error>}
        </FormGroup>
        <AuthButton theme={theme}>Sign Up</AuthButton>
        <Text>Already have an account? <Link href="/Login">Login</Link></Text>
        <SocialButtons>
          <SocialButton theme={theme}>Google</SocialButton>
          <SocialButton theme={theme}>Facebook</SocialButton>
        </SocialButtons>
      </AuthForm>
    </AuthContainer>
  );
};

const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: ${props => props.theme === 'light' ? '#f9f9f9' : '#333'};
  color: ${props => props.theme === 'light' ? '#333' : '#f9f9f9'};
`;

const AuthForm = styled.form`
  width: 100%;
  max-width: 400px;
  background-color: ${props => props.theme === 'light' ? '#fff' : '#444'};
  padding: 2rem;
  border-radius: 10px;
  box-shadow: ${props => props.theme === 'light' ? '0 4px 6px rgba(0, 0, 0, 0.1)' : '0 4px 6px rgba(255, 255, 255, 0.1)'};
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
  position: relative;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid ${props => props.theme === 'light' ? '#ddd' : '#666'};
  border-radius: 5px;
  background-color: ${props => props.theme === 'light' ? '#fff' : '#555'};
  color: ${props => props.theme === 'light' ? '#333' : '#fff'};
  box-shadow: ${props => props.theme === 'light' ? '0 2px 4px rgba(0, 0, 0, 0.1)' : '0 2px 4px rgba(255, 255, 255, 0.1)'};
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: ${props => props.theme === 'light' ? '#007bff' : '#007bff'};
  }
`;

const PasswordWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PasswordIcon = styled.div`
  margin-left: -2rem;
  cursor: pointer;
  color: ${props => props.theme === 'light' ? '#333' : '#fff'};
`;

const Error = styled.div`
  color: red;
  margin-top: 0.5rem;
  font-size: 0.875rem;
`;

const AuthButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  background-color: ${props => props.theme === 'light' ? '#007bff' : '#1a73e8'};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: ${props => props.theme === 'light' ? '0 4px 6px rgba(0, 0, 0, 0.1)' : '0 4px 6px rgba(255, 255, 255, 0.1)'};
  transition: background-color 0.3s;

  &:hover {
    background-color: ${props => props.theme === 'light' ? '#0056b3' : '#135ab6'};
  }
`;

const SocialButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const SocialButton = styled.button`
  padding: 0.75rem;
  background-color: ${props => props.theme === 'light' ? '#fff' : '#444'};
  color: ${props => props.theme === 'light' ? '#333' : '#fff'};
  border: 1px solid ${props => props.theme === 'light' ? '#ddd' : '#666'};
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: ${props => props.theme === 'light' ? '0 4px 6px rgba(0, 0, 0, 0.1)' : '0 4px 6px rgba(255, 255, 255, 0.1)'};
  transition: background-color 0.3s;

  &:hover {
    background-color: ${props => props.theme === 'light' ? '#f9f9f9' : '#555'};
  }
`;

const Text = styled.p`
  margin-top: 1rem;
  text-align: center;
`;

const Link = styled.a`
  color: ${props => props.theme === 'light' ? '#007bff' : '#007bff'};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid ${props => props.theme === 'light' ? '#ddd' : '#666'};
  border-radius: 5px;
  background-color: ${props => props.theme === 'light' ? '#fff' : '#555'};
  color: ${props => props.theme === 'light' ? '#333' : '#fff'};
  box-shadow: ${props => props.theme === 'light' ? '0 2px 4px rgba(0, 0, 0, 0.1)' : '0 2px 4px rgba(255, 255, 255, 0.1)'};
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: ${props => props.theme === 'light' ? '#007bff' : '#007bff'};
  }
`;

export {ReferralSignup};

