import { useEffect, useState } from 'react';
import axios from 'axios';
import { showErrorNotification } from '../components/Layouts/Notification'
import { apiUrl } from '../apiUrl';

axios.defaults.withCredentials = true;

const useUserAuthHook = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios.get(`${apiUrl}/profile`, {
      withCredentials: true,
    }).then(response => {
    setData(JSON.parse(localStorage.getItem('user')) || response.data);
    }).catch(err => {
    showErrorNotification("errors " + err);
    setData(null);
    });
    },[setData]);

  return data;
};

export default useUserAuthHook;